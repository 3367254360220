const config = {
	apiKey: "AIzaSyAR2mNqEqFWMtvAMelemQknCddCRaEVAjw",
	authDomain: "sad-bi.firebaseapp.com",
	databaseURL: "https://sad-bi-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "sad-bi",
	storageBucket: "sad-bi.appspot.com",
	messagingSenderId: "707945606395",
	appId: "1:707945606395:web:a130c506e33be54209545e",
	measurementId: "G-P69FQGBLYC"
};

export default config