<script>
import { format, subDays, addDays, isEqual, isSameMonth, startOfMonth, endOfMonth } from 'date-fns';

export default {
	props: {
		default_label: {
			type: String,
			required: false
		},
		value: {
			type: Object,
			required: false
		},
		compact: {
			type: Boolean,
			required: false,
			default: true
		},
		noFutureValues: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	methods: {
		click_1day: function (evt) {
			this.custom_set = true;
			this.title = 'Danes';
			let start = new Date();
			this.selectedDate = { start: start, end: start };
			this.selected();
		},
		click_prev7days: function (evt) {
			this.custom_set = true;
			this.title = '-7 dni';
			let end = new Date();
			let start = subDays(end, 7);
			this.selectedDate = { start: start, end: end };
			this.selected();
		},
		click_prev30days: function (evt) {
			this.custom_set = true;
			this.title = '-30 dni';
			let end = new Date();
			let start = subDays(end, 30);
			this.selectedDate = { start: start, end: end };
			this.selected();
		},
		click_next7days: function (evt) {
			this.custom_set = true;
			this.title = '+7 dni';
			let start = new Date();
			let end = addDays(start, 7);
			this.selectedDate = { start: start, end: end };
			this.selected();
		},
		click_next30days: function (evt) {
			this.custom_set = true;
			this.title = '+30 dni';
			let start = new Date();
			let end = addDays(start, 30);
			this.selectedDate = { start: start, end: end };
			this.selected();
		},
		click_thisMonth: function (evt) {
			this.custom_set = true;
			this.title = 'Ta mesec';
			let start = startOfMonth(new Date());
			let end = endOfMonth(start);
			this.selectedDate = { start: start, end: end };
			this.selected();
		},
		click_prev3months: function (evt) {
			this.custom_set = true;
			this.title = '-3 mesece';
			let end = new Date();
			let start = subDays(end, 90);
			this.selectedDate = { start: start, end: end };
			this.selected();
		},
		click_prev6months: function (evt) {
			this.custom_set = true;
			this.title = '-6 mesece';
			let end = new Date();
			let start = subDays(end, 180);
			this.selectedDate = { start: start, end: end };
			this.selected();
		},
		click_clear: function () {
			this.clear();
			this.$emit('input', this.selectedDate);
			this.selected();
		},
		selected: function (env) {
			if (!this.selectedDate || !this.selectedDate.start || !this.selectedDate.end) {
				this.$emit('input', this.selectedDate);
				this.$refs.dropdown.hide();
				return;
			}
			if (!this.custom_set) {
				if (isEqual(this.selectedDate.start, this.selectedDate.end)) {
					// en dan
					this.is_smalltext_class = false;
					this.title = format(this.selectedDate.start, 'dd.MM.yyyy');
				} else if (isSameMonth(this.selectedDate.start, this.selectedDate.end)) {
					// znotraj meseca
					this.is_smalltext_class = true;
					this.title = format(this.selectedDate.start, 'd') + '.-' + format(this.selectedDate.end, 'd.MM') + '.';
				} else {
					// custom
					this.is_smalltext_class = true;
					this.title = format(this.selectedDate.start, 'd.MM') + '.-' + format(this.selectedDate.end, 'd.MM') + '.';
				}
			} else {
				this.is_smalltext_class = false;
			}
			this.custom_set = false;
			this.$emit('input', this.selectedDate);
			this.$refs.dropdown.hide();
		},
		clear: function () {
			this.is_smalltext_class = false;
			this.custom_set = false;
			this.title = null;
			this.selectedDate = null;
		}
	},
	computed: {
		selectedTitle () {
			if (this.title) {
				return this.title;
			} else if (this.default_label) {
				return this.default_label;
			} else {
				return this.default_label_empty;
			}
		},
		showCompact () {
			if (this.compact != null) {
				return this.compact;
			} else {
				return true;
			}
		}
	},
	data: function () {
		return {
			is_smalltext_class: false,
			custom_set: false,
			title: null,
			selectedDate: null,
			default_label_empty: 'Vse'
		}
	},
	created () {
	}
}
</script>
<template>
	<b-dropdown variant="primary" :text="selectedTitle" ref="dropdown" :class="{ smalldt: (is_smalltext_class && showCompact) }">
		<b-dropdown-form class="date-dd">
			<b-row>
				<b-col sm="7" class="ml-2">
					<v-date-picker
						v-model="selectedDate"
						is-inline 
						:max-date="noFutureValues ? new Date() : null"
						is-expanded
						is-range 
						locale="sl-SI"
						@input="selected"/>
					<b-button variant="primary" class="mt-2" size="sm" @click.prevent="click_clear">Odstrani</b-button>
				</b-col>
				<b-col sm="4" class="pl-1 pr-1 m-0">
					<b-list-group>
						<b-list-group-item class="pb-2 pt-2" @click.prevent="click_prev30days">Zadnjih 30 dni</b-list-group-item>
						<b-list-group-item class="pb-2 pt-2" @click.prevent="click_prev7days">Zadnjih 7 dni</b-list-group-item>
						<b-list-group-item class="pb-2 pt-2" @click.prevent="click_1day">Danes</b-list-group-item>
						<b-list-group-item class="pb-2 pt-2" v-if="noFutureValues == false" @click.prevent="click_next7days">Naslednjih 7 dni</b-list-group-item>
						<b-list-group-item class="pb-2 pt-2" v-else @click.prevent="click_prev3months">Zadnje 3 mesece</b-list-group-item>
						<b-list-group-item class="pb-2 pt-2" v-if="noFutureValues == false" @click.prevent="click_next30days">Naslednjih 30 dni</b-list-group-item>
						<b-list-group-item class="pb-2 pt-2" v-else  @click.prevent="click_prev6months">Zadnjih 6 mesecev</b-list-group-item>
						<b-list-group-item class="pb-2 pt-2" @click.prevent="click_thisMonth">Ta mesec</b-list-group-item>
					</b-list-group>
				</b-col>
			</b-row>
		</b-dropdown-form>
	</b-dropdown>
</template>
<style scoped>
.date-dd {
	width: 34rem!important;
}
.smalldt button.btn {
	font-size: 0.6rem;
}
</style>