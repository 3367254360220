<script>
import { VEHICLE_BRANDS_LIST_ALL_ERROR } from "@/store/actions/vehicleBrands";
import imagesTool from '@/tools/images';

export default {
	name: 'SpOrderBrandsFilterDropdown',
	methods: {
		click_item (item) {
			this.allSelected = false;
			this.selectItem(item.id);
			this.$emit('change', this.selectedItems);
		},
		click_all (evt) {
			this.selectItem();
			this.allSelected = true;
			this.$emit('change', []);
		},
		selectItem (value) {
			this.selectedItems = [];
			for (let item of this.items) {
				if (this.multiselect) {
					if (value) {
						if (value === item.id) {
							item.active = !item.active;
						}
					} else {
						item.active = false;
					}
				} else {
					if (value) {
						if (value === item.id) {
							item.active = true;
						} else {
							item.active = false;
						}
					} else {
						item.active = false;
					}
				}
				if (item.active) {
					this.selectedItems.push(item);
				}
			}
			if (this.selectedItems.length === 0) {
				this.allSelected = true;
			}
		},
		clear () {
			this.selectItem();
		}
	},
	computed: {
		selectedTitle () {
			return this.defaultItemLabel;
		},
		profile () {
			return this.$store.getters.getProfile;
		},
		items () {
			if (this.$store.getters.getAllVehicleBrands) {
				if (this.$store.getters.getUserSpOrdersBrandsFilter && this.$store.getters.getUserSpOrdersBrandsFilter.length > 0) {
					this.allSelected = false;
					return this.$store.getters.getAllVehicleBrands;
				} else {
					return this.$store.getters.getAllVehicleBrands.map(el => ({...el, active: false}));
				}
			} else {
				this.$store.dispatch(VEHICLE_BRANDS_LIST_ALL)
					.then((responseData) => {
						return responseData.vehicleBrands.map(el => ({...el, active: false}));
					})
					.catch(error => {
						console.error(error);
					});
			}
		}
	},
	data: () => {
		return {
			defaultItemLabel: 'Vse',
			selectedItems: [],
			multiselect: true,
			allSelected: true,
			imagesTool
		}
	}
}
</script>
<template>
<b-dropdown variant="primary" :text="selectedTitle" ref="dropdown">
	 <template #button-content>
		<span v-if="allSelected">Vse</span>
		<span v-else v-for="item in items" :key="item.id" >
			<span v-if="item.active">
				<div v-if="item.logo" :title="item.title" :style="imagesTool.vehicleBrandImage(item.logo, 1)"></div>
				<CIcon v-else :title="item.title" :style="imagesTool.vehicleBrandIcon(0.8)" name="cil-car-alt"/>
			</span>
		</span>
	 </template>
	<b-dropdown-header>Znamka</b-dropdown-header>
	<b-dropdown-item @click="click_item(item)" v-for="item in items" :key="item.id" :active="item.active">
		{{ item.title }}
	</b-dropdown-item>
	<b-dropdown-divider></b-dropdown-divider>
	<b-dropdown-item @click="click_all" :active="allSelected">{{ defaultItemLabel }}</b-dropdown-item>
</b-dropdown>
</template>
<style>
</style>