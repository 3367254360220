<template>
	<b-modal :no-close-on-backdrop="true" :hide-header-close="isLoading" ref="modal" :title="title" size="1600" class="modal-primary" @hidden="clearModalData">
		<CRow>
			<CCol>
				<b-card>
					<div slot="header">
						<b>Naročilo</b>
					</div>
					<CRow>
						<CCol v-if="editingDisabled">
							<CInput
								:disabled = editingDisabled
								label="Datum"
								:value="spOrder.date | viewDate"/>
						</CCol>
						<CCol v-else>
							<v-date-picker
								locale="sl-SI"
								@input="setSpOrderEditing"
								v-model="spOrder.date"
								:model-config=" {
									type: 'string',
								}"
								timezone='utc'>
								<template v-slot="{ inputValue, inputEvents }">
									<CInput
										label="Datum"
										:value="inputValue"
										v-on="inputEvents"/>
								</template>
							</v-date-picker>
						</CCol>
						<CCol>
							<CRow>
								<CCol sm="10" >
									<CInput
										@input="setSpOrderEditing"
										:disabled = editingDisabled
										label="Št. DN"
										v-model="spOrder.spOrderNumber"/>
								</CCol>
								<CCol sm="2" class="p-sm-0">
									<div>
										<b-badge v-if="spOrderNumberCopied" variant="primary">Copied!</b-badge>
									</div>
									<div :class="spOrderNumberCopied ? 'copyButtonClicked' : 'copyButton'" >
										<CLink color="primary" title="Kopiraj št. DN" @click.stop="copyToCliboard(spOrder.spOrderNumber, 'spOrderNumber')">
											<CIcon name="cil-copy"  size="lg"/>
										</CLink>
									</div>
								</CCol>
							</CRow>
						</CCol>
						<CCol>
							<label>Oddelek</label>
							<v-select
								@input="setSpOrderEditing"
								:disabled="editingDisabled"
								v-model="spOrder.department"
								:options="settings.spOrderDepartmentOptions"
								:taggable="true"
								placeholder="Izberi oddelek ...">
							</v-select>
						</CCol>
					</CRow>
					<CRow>
						<CCol>
							<CRow>
								<CCol>
									<label>VIN</label>
									<v-select
										:disabled="editingDisabled"
										v-model="spOrder.vin"
										label="ogrodje"
										:options="vehicle_options"
										:taggable="true"
										@input="vehicleSelect_change($event)"
										@search="vehicleSelect_search"
										placeholder="Izberi vozilo ...">
										<template slot="no-options">Vnesite VIN številko vozila</template>
										<template slot="option" slot-scope="option">
											<div v-if="option.id">{{ option.ogrodje }}</div>
											<div v-else><b>Dodaj vozilo {{option.ogrodje}}</b></div>
										</template>
									</v-select>
								</CCol>
								<CCol sm="2" class="p-sm-0">
									<div>
										<b-badge v-if="vinCopied" variant="primary">Copied!</b-badge>
									</div>
									<div :class="vinCopied ? 'copyButtonClicked' : 'copyButton'" >
										<CLink color="primary"  title="Kopiraj VIN" @click.stop="copyToCliboard(spOrder.vin, 'vin')">
											<CIcon name="cil-copy" size="lg"/>
										</CLink>
									</div>
								</CCol>
							</CRow>
						</CCol>
						<CCol>
							<label>Znamka</label>
							<v-select
								:disabled="editingDisabled"
								v-model="selectedBrand"
								:options="allVehicleBrands"
								:filter-by="filter_brandsSelect"
								label="title"
								@input="change_brandsSelect"
								placeholder="Izberi znamko ...">
								<template slot="no-options">Znamka ne obstaja</template>
								<template slot="option" slot-scope="option">
									<b-row>
										<b-col cols=2 class="p-0 m-0">
											<div v-if="option.logo" :style="imagesTool.vehicleBrandImage(option.logo, 2)"></div>
											<CIcon v-else :style="imagesTool.vehicleBrandIcon(2)" name="cil-car-alt"/>
										</b-col>
										<b-col class="pl-0 pt-2">
											{{ option.title }}
										</b-col>
									</b-row>
								</template> 
								<template slot="selected-option" slot-scope="option">
									<div v-if="option.logo" :style="imagesTool.vehicleBrandImage(option.logo, 1.3)"></div>
									<CIcon v-else :style="imagesTool.vehicleBrandIcon(1.3)" name="cil-car-alt"/>
									<div class="ml-2">{{ option.title }}</div>
								</template>
							</v-select>
						</CCol>
						<CCol>
							<CInput
								@input="setSpOrderEditing"
								:disabled = editingDisabled
								label="Model"
								v-model="spOrder.model"/>
						</CCol>
					</CRow>
					<CRow>
						<CCol>
							<CRow>
								<CCol>
									<label>Stranka</label>
									<v-select
										:disabled="editingDisabled"
										ref="vSelectClient"
										v-model="selectedSpOrderClient"
										:options="allSpOrderClients"
										:filter-by="filter_clientsSelect"
										:taggable="true"
										label="first_name"
										@input="change_spOrderClientsSelect"
										placeholder="Izberi stranko ...">
										<template slot="no-options">Stranka ne obstaja</template>
										<template slot="option" slot-scope="option">
											<div v-if="option.id">{{ getClientSelectLabel(option )}}</div>
											<div v-else><b>Dodaj stranko {{option.first_name}}</b></div>
										</template>
										<template slot="selected-option" slot-scope="option">
											{{ getClientSelectLabel(option, 'selected-option')}}
										</template>
									</v-select>
								</CCol>
								<CCol sm="2" class="p-sm-0">
									<div class="clientEditButton" >
										<CLink v-if="selectedSpOrderClient" title="Uredi stranko" href="#" @click.stop="click_editClient">
											<CIcon name="cil-pencil"/>
										</CLink>
									</div>
								</CCol>
							</CRow>
						</CCol>
						<CCol>
							<CRow>
								<CCol sm="10">
									<CInput
										@input="setSpOrderEditing"
										:disabled = editingDisabled
										label="Registrska številka"
										v-model="spOrder.registrationNumber"/>
								</CCol>
								<CCol sm="2" class="p-sm-0">
									<div >
										<b-badge v-if="registrationNumberCopied" variant="primary">Copied!</b-badge>
									</div>
									<div :class="registrationNumberCopied ? 'copyButtonClicked' : 'copyButton'" >
										<CLink color="primary" title="Kopiraj reg. št." @click.stop="copyToCliboard(spOrder.registrationNumber, 'registrationNumber')">
											<CIcon name="cil-copy" size="lg"/>
										</CLink>
									</div>
								</CCol>
							</CRow>
						</CCol>
						<CCol>
							<label>Lokacija</label>
							<v-select
								@input="setSpOrderEditing"
								:disabled="editingDisabled"
								v-model="spOrder.location"
								:options="settings.spOrderLocationOptions"
								:taggable="true"
								placeholder="Izberi lokacijo ...">
								<template slot="option" slot-scope="option">
									{{ getLocationLabel(option) }}
								</template>
								<template slot="selected-option" slot-scope="option">
									{{ getLocationLabel(option, 'selected-option')}}
								</template>
							</v-select>
						</CCol>
					</CRow>
					<div v-if="false" slot="footer">
						<b-row>
							<b-col class="ma_pos">
								<b-button v-if="mode !== mode_CREATE1" :disabled="!isSpOrderEditing" title="Razveljavi spremembe" variant="danger" @click.stop="click_spOrderEditCancel()" size="sm" class="mr-2">
									<CIcon name="cil-x"/>
								</b-button>
								<b-button :disabled="!isSpOrderEditing" :title="spOrderOkButton.title" variant="success" @click.stop="click_spOrderCreate()" size="sm" class="mr-2">
									<CIcon name="cil-check-alt"/>
								</b-button>
							</b-col>
						</b-row>
					</div>
				</b-card>
			</CCol>
		</CRow>
		<CRow v-if="mode == mode_EDIT2 || mode == mode_DETAILS3">
			<CCol>
				<b-card class="spOrderItemsTable">
					<div slot="header">
						<b>Postavke</b>
					</div>
						<b-table
							:class="spOrderItemsTableClass"
							ref="spOrderItemsTable"
							striped
							responsive
							hover
							show-empty
							empty-text="Ni postavk"
							:fields="spOrderItemsTableFields"
							:items="spOrderItemsTable_items"
							:current-page="spOrderItemsTableProps.currentPage"
							:per-page="spOrderItemsTableProps.itemsPerPage">
							<template v-if="!editingDisabled" slot="top-row">
								<td class="addingSpOrderItemsRow"><CInput class="inTableInput" :disabled="isSpOrderItemEditing" type="text" v-model="newSpOrderItem.catalog_number"/></td>
								<td class="addingSpOrderItemsRow"><CInput class="inTableInput" :disabled="isSpOrderItemEditing" type="text" v-model="newSpOrderItem.title"/></td>
								<td class="addingSpOrderItemsRow"><CInput class="inTableInput" :disabled="isSpOrderItemEditing" type="text" v-model="newSpOrderItem.note"/></td>
								<td class="addingSpOrderItemsRow">
									<v-select
										:disabled="isSpOrderItemEditing"
										v-model="newSpOrderItem.status"
										:options="spOrderItemsStatusOptions"
										@input="newSpOrderItemStatusChange(newSpOrderItem.item)"
										placeholder="Izberi status ..."
										:searchable="false">
										<template slot="no-options">Ni statusov</template>
										<template slot="option" slot-scope="option">
											<b-badge class="text-wrap" :variant="option.variant">{{ option.label }}</b-badge>
										</template>
										<template slot="selected-option" slot-scope="option">
											<b-badge class="text-wrap" :variant="option.variant">{{ option.label }}</b-badge>
										</template>
									</v-select>
								</td>
								<td class="addingSpOrderItemsRow">
									<sp-order-item-estimated-date-dropdown :disabled="isSpOrderItemEditing"  v-model="newSpOrderItem.estimated_delivery_date" @input="newSpOrderItemEstimatedDateInput"/>
								</td>
								<td class="addingSpOrderItemsRow"><CInputCheckbox class="inTableInput" :disabled="isSpOrderItemEditing" :custom="true" :checked.sync="newSpOrderItem.in_house" label="" inline @input="newSpOrderItemInHouseChange"/></td>
								<td class="ma_pos addingSpOrderItemsRow">
									<b-button title="Prekliči" :disabled="isSpOrderItemEditing" variant="danger" @click.stop="click_spOrderItemClear()" size="sm" class="mr-2">
										<CIcon name="cil-x"/>
									</b-button>
									<b-button :disabled="isSpOrderItemEditing" title="Dodaj postavko" variant="primary" @click.stop="click_spOrderItemCreate()" size="sm" class="mr-2">
										<CIcon name="cil-plus"/>
									</b-button>
								</td>
							</template>
							<template v-slot:cell(catalog_number)="data">
								<CRow>
									<CCol>
										<div v-if="!editingDisabled">
											<CInput
												class="inTableInput"
												@input="setSpOrderItemEditing(data.item)"
												:disabled="isEditingSpOrderItemDisabled(data.item)"
												type="text"
												v-model="data.item.catalog_number"/>
										</div>
										<div v-else>
											{{ data.item.catalog_number }}
										</div>
									</CCol>
									<CCol col=2>
										<CIcon class="mb-1" name="cil-calendar" :title="data.item.created_at | viewDateTime"/>
									</CCol>
								</CRow>
							</template>
							<template v-slot:cell(title)="data">
								<div v-if="!editingDisabled">
									<CInput
										class="inTableInput"
										@input="setSpOrderItemEditing(data.item)"
										:disabled="isEditingSpOrderItemDisabled(data.item)"
										type="text"
										v-model="data.item.title"/>
								</div>
								<div v-else>
									{{ data.item.title }}
								</div>
							</template>
							<template v-slot:cell(status)="data">
								<div v-if="!editingDisabled">
									<v-select
										:disabled="isEditingSpOrderItemDisabled(data.item)"
										v-model="data.item.status"
										@input="spOrderItemStatusChange(data.item)"
										:options="spOrderItemsStatusOptions"
										placeholder="Izberi status ..."
										:searchable="false">
										<template slot="no-options">Ni statusov</template>
										<template slot="option" slot-scope="option">
											<b-badge class="text-wrap" :variant="option.variant">{{ option.label }}</b-badge>
										</template>
										<template slot="selected-option" slot-scope="option">
											<b-badge class="text-wrap" :variant="option.variant" :title="getStatusTitle(data.item)">{{ option.label }}</b-badge>
										</template>
									</v-select>
								</div>
								<div v-else>
									<b-badge class="text-wrap" v-if="data.item.status" :variant="data.item.status.variant"  :title="getStatusTitle(data.item)">{{ data.item.status.label }}</b-badge>
								</div>
							</template>
							<template v-slot:cell(estimated_delivery_date)="data">
								<CRow>
									<CCol>
									<div v-if="!editingDisabled">
									<sp-order-item-estimated-date-dropdown :disabled="isEditingSpOrderItemDisabled(data.item)" v-model="data.item.estimated_delivery_date" :default_label="getEstimatedDeliveryDateLabel(data.item)" @input="setSpOrderItemEditing(data.item)"/>
								</div>
								<div v-else>
									{{ getEstimatedDeliveryDateLabel(data.item) }}
								</div>
									</CCol>
									<CCol col=2>
										<CIcon v-if="data.item.in_house_set_by_user" class="mb-1" name="cil-user" :title="data.item.in_house_set_by_user.name"/>
									</CCol>
								</CRow>
							</template>
							<template v-slot:cell(note)="data">
								<div v-if="!editingDisabled">
									<CInput
										class="inTableInput"
										@input="setSpOrderItemEditing(data.item)"
										:disabled="isEditingSpOrderItemDisabled(data.item)"
										type="text"
										v-model="data.item.note"/>
								</div>
								<div v-else>
									{{ data.item.note }}
								</div>
							</template>
							<template v-slot:cell(in_house)="data">
								<CInputCheckbox 
									class="inTableInput"
									@input="spOrderItemInHouseChange(data.item)"
									:disabled="editingDisabled || isEditingSpOrderItemDisabled(data.item)" 
									:custom="true" 
									:checked="!!data.item.in_house" 
									label="" 
									inline/>
							</template>
							<template v-if="!editingDisabled" v-slot:cell(actions)="data">
								<div class="ma_pos">
									<b-button v-if="!data.item.edited" :disabled="isEditingSpOrderItemDisabled(data.item)" title="Odstrani" variant="danger" @click.stop="click_spOrderItemRemove(data.item)" size="sm" class="mr-2">
										<CIcon name="cil-trash"/>
									</b-button>
									<b-button v-else title="Prekliči" variant="danger" @click.stop="click_spOrderItemCancel(data.item)" size="sm" class="mr-2">
										<CIcon name="cil-x"/>
									</b-button>
									<b-button :disabled="!data.item.edited" title="Shrani" variant="primary" @click.stop="click_spOrderItemEdit(data.item)" size="sm" class="mr-2">
										<CIcon name="cil-check-alt"/>
									</b-button>
								</div>
							</template>
						</b-table>
						<nav v-if="showSpOrderItemsTableNav">
							<b-row>
								<b-col>
									<b-pagination :total-rows="spOrderItemsTableProps.totalItems" :per-page="spOrderItemsTableProps.itemsPerPage" v-model="spOrderItemsTableProps.currentPage" prev-text="Prejšnja" next-text="Naslednja" hide-goto-end-buttons />
								</b-col>
								<b-col cols=2>
									<a class="page-link rows_total float-right">Št. vrstic: {{ spOrderItemsTableProps.totalItems }}</a>
								</b-col>
								<b-col cols=1>
									<b-form-select v-model="spOrderItemsTableProps.itemsPerPage" :options="spOrderItemsTableProps.options_numRowsOnPage"></b-form-select>
								</b-col>
							</b-row>
						</nav>
						<vue-loading :active.sync="spOrderItemsTableProps.isLoading" :is-full-page="false"/>
				</b-card>
			</CCol>
		</CRow>
		<CAlert v-show="message.text != null" :color="message.type">
			{{ message.text }}
		</CAlert>
		<template slot="modal-footer" slot-scope="{ cancel }">
			<b-container fluid class="p-0">
				<b-row>
					<b-col align-self="end" class="ma_pos">
						<b-button :disabled="isLoading" style="margin-right:5px;" variant="secondary" @click="cancel()">Zapri</b-button>
						<span v-if="permissionCheck.can('sp-orders-write')">
							<b-button v-if="editingRecord.type && isCurrentlyEdited() && !editingRecord.forced_edit" :disabled="isEditingDisabled()" :title="getEditingLockTitle()" variant="primary" @click.stop="click_spOrderForceEdit()" size="sm" class="mr-2">
								<CIcon name="cil-lock-locked"/>
							</b-button>
							<b-button v-else-if="mode != mode_UNKNOWN0" :disabled="isLoading || isSpOrderItemEditing" style="margin-right:5px;" variant="primary" @click="click_enableEditing()" :title="modalOkButtonData.title">{{ modalOkButtonData.label }}</b-button>
						</span>
					</b-col>
				</b-row>
			</b-container>
		</template>
		<vue-loading :active.sync="isLoading" :is-full-page="false"/>
		<sp-order-client-create-edit-modal ref="spOrderClientCreateEditModal" @emit_spOrderClientCreatedOrEdited="emit_spOrderClientCreatedOrEdited" @emit_spOrderClientModalCancel="emit_spOrderClientModalCancel"/>
	</b-modal>
</template>

<script>
import settings from '@/settings.js';
import imagesTool from '@/tools/images';
import { SP_ORDER_CLIENTS_LIST_ALL, SP_ORDER_CLIENT_CREATE } from "@/store/actions/spOrderClients";
import { VEHICLE_BRANDS_LIST_ALL } from "@/store/actions/vehicleBrands";
import { SP_ORDER_CREATE, SP_ORDER_GET, SP_ORDER_SET_VIEWED } from "@/store/actions/spOrders";
import { SP_ORDER_ITEM_CREATE, SP_ORDER_ITEMS_LIST_FILTERED, SP_ORDER_ITEM_DELETE } from "@/store/actions/spOrderItems";
import { VEHICLES_LIST_FILTERED } from "@/store/actions/vehicles";
import { EDITING_RECORD_START, EDITING_RECORD_END } from "@/store/actions/editingRecords";
import PermissionCheck from '@/tools/permission-check';
import _ from 'lodash';
import SpOrderItemEstimatedDateDropdown from '@/views/components/SpOrderItemEstimatedDateDropdown';
import { addDays, addSeconds } from 'date-fns';

export default {
	name: 'sp-order-create-edit-modal',
	components: {
		'sp-order-item-estimated-date-dropdown': SpOrderItemEstimatedDateDropdown
	},
	props: {
	},
	methods: {
		showModal: function (modalInput) {
			/*
				mode 1 => Create
			*/
			let today = new Date();
			today.setUTCHours(0,0,0,0);
			this.spOrder.date = today.toISOString();
			this.mode = modalInput.mode;
			this.editingRecord.type = modalInput.editingRecordType ?? null;
			this.editingRecord.forced_edit = modalInput.forced_edit ?? false;
			this.initModal(modalInput.spOrder);
		},
		updateModalData: function (updatedSpOrder) {
			if (this.mode === this.mode_EDIT2) {
				// this.showAlert('Naročilo je spremenil drug uporabnik! Če želite posodobljene podatke zaprite in ponovno odprite naročilo.', this.ALERT_TYPE_ERROR);
			} else if (this.mode === this.mode_DETAILS3) {
				this.initSpOrder(updatedSpOrder);
				this.$refs.spOrderItemsTable.refresh();
			}
		},
		setViewedRecord: function (spOrderId) {
			this.startProgress();
			this.$store.dispatch(SP_ORDER_SET_VIEWED, { sp_order_id: spOrderId })
				.then((responseData) => {
					this.$emit('emit_spOrderSetView', responseData.userViewedSpOrder);
					this.stopProgress();
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
				});
		},
		initModal: function (spOrder) {
			if (this.mode === this.mode_CREATE1) {
				this.title = 'Dodajanje naročila ND';
				this.spOrderOkButton.title = 'Dodaj';
				this.spOrder.department = this.settings.spOrderDepartmentOptions[0];
				this.isSpOrderEditing = true;
			} else if (this.mode === this.mode_EDIT2 && this.permissionCheck.can('sp-orders-write')) {
				this.spOrderItemsTableProps.inputFilter.spOrderId = spOrder.id;
				this.title = 'Urejanje naročila ND';
				this.spOrderOkButton.title = 'Shrani spremembe';
				this.initSpOrder(spOrder);
				if (this.editingRecord.type) {
					this.startProgress();
					this.$store.dispatch(EDITING_RECORD_START, { editingRecord: this.editingRecord })
						.then((responseData) => {
							this.stopProgress();
							if (responseData.editingRecord) {
								this.$emit('emit_spOrderChange');
								this.editingRecord.id = responseData.editingRecord.id;
							} else {
								this.$emit('emit_spOrderEditingRecordStartError');
								this.$refs.modal.hide();
							}
						})
						.catch(error => {
							this.stopProgress();
							this.$emit('emit_spOrderEditingRecordStartError');
							this.$refs.modal.hide();
							console.error(error);
						});
				}
			} else if (this.mode === this.mode_DETAILS3) {
				this.spOrderItemsTableProps.inputFilter.spOrderId = spOrder.id;
				this.title = 'Naročilo #' + spOrder.id;
				this.okButton.label = 'Uredi';
				this.initSpOrder(spOrder);
			} else {
				this.mode = this.mode_UNKNOWN0;
				this.title = 'Neznan način';
				this.okButton.label = 'Zapri';
			}
			this.$refs.modal.show();
		},
		initSpOrder: function (spOrder) {
			if ((!spOrder.viewed_record || (spOrder.viewed_record && spOrder.viewed_record.viewed_at === null))) {
				this.setViewedRecord(spOrder.id);
			}
			this.spOrder.id = spOrder.id;
			this.spOrder.date = spOrder.date;
			this.spOrder.spOrderNumber = spOrder.sp_order_number;
			this.spOrder.department = spOrder.department;
			if (spOrder.vehicle) {
				this.spOrder.vin = spOrder.vehicle.ogrodje;
			}
			this.spOrder.registrationNumber = spOrder.registration_number;
			this.spOrder.brandId = spOrder.brand_id;
			this.selectedBrand = this.allVehicleBrands.find(brand => brand.id === spOrder.brand_id);
			this.spOrder.model = spOrder.model;
			this.spOrder.location = spOrder.location;
			this.spOrder.clientId = spOrder.client_id;
			this.selectedSpOrderClient = this.allSpOrderClients.find(client => client.id === spOrder.client_id);
			if (this.selectedSpOrderClient && spOrder.client && spOrder.client.updated_at != this.selectedSpOrderClient.updated_at) {
				this.startProgress();
				this.$store.dispatch(SP_ORDER_CLIENTS_LIST_ALL)
					.then((responseData) => {
						this.selectedSpOrderClient = this.allSpOrderClients.find(client => client.id === spOrder.client_id);
						this.stopProgress();
					})
					.catch(error => {
						console.error(error);
					});
			}
			if (this.editingRecord.type) {
				this.editingRecord.object_id = spOrder.id;
				this.spOrder.latestEditingRecord = spOrder.latest_editing_record;
			}},
		click_spOrderCreate: function () {
			this.startProgress();
			let spOrderData = {...this.spOrder};
			let isNewBrand = this.selectedBrand && this.selectedBrand.title && !this.selectedBrand.id;
			if (isNewBrand) {
				spOrderData.brandTitle = this.selectedBrand.title;
			}
			this.$store.dispatch(SP_ORDER_CREATE, { spOrder: spOrderData })
				.then((responseData) => {
					this.stopProgress();
					if (this.mode === this.mode_CREATE1) {
						this.$emit('emit_spOrderChange', responseData);
						this.showAlert('Naročilo #' + responseData.spOrder.id + ' uspešno dodano!', this.ALERT_TYPE_SUCCESS, 5);
						this.isSpOrderEditing = false;
						this.mode = this.mode_EDIT2;
						this.initModal(responseData.spOrder);
						if (isNewBrand) {
							this.$store.dispatch(VEHICLE_BRANDS_LIST_ALL)
								.then((vehicleBrandsResponseData) => {
									this.selectedBrand = this.allVehicleBrands.find(brand => brand.id === responseData.spOrder.brand_id);
									this.spOrder.brand_id = this.selectedBrand.id;
								})
								.catch(error => {
									console.error(error);
								});
						}
					} else if (this.mode === this.mode_EDIT2 && !this.editingRecord.type) {
						this.$emit('emit_spOrderChange', responseData);
					} else {
						this.$refs.modal.hide();
					}
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						if (this.mode === this.mode_CREATE1) {
							this.showAlert('Napaka pri dodajanju! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						} else {
							this.showAlert('Neznan način modala! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						}
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		},
		clearModalData: function () {
			this.isLoading = false;
			this.title = '';
			this.okButton = {
				label: 'Shrani',
				progress: false,
				disabled: false
			};
			this.showAlert();
			if (this.mode === this.mode_EDIT2 && this.editingRecord.type) {
				this.$store.dispatch(EDITING_RECORD_END, { editingRecord: this.editingRecord })
					.then((responseData) => {
						this.$emit('emit_spOrderChange', responseData);
					})
					.catch(error => {
						console.error(error);
					});
			}
			this.mode = 0;
			this.resetSpOrderObject();
			this.resetSpOrderItemsTable();
			this.resetSpOrderItemObject();
			this.isSpOrderItemEditing = false;
			this.vehicle_options = [];
			this.isSpOrderEditing = false;
			this.editingRecord = {
				id: null,
				type: null,
				object_id: null,
				data_at_start: null,
				forced_edit: false
			};
			this.$emit('emit_spOrderModalClosed');
		},
		resetSpOrderObject: function () {
			this.spOrder.id = null;
			this.spOrder.date = null;
			this.spOrder.spOrderNumber = null;
			this.spOrder.department = null;
			this.spOrder.vin = null;
			this.spOrder.registrationNumber = null;
			this.spOrder.brandId = null;
			this.spOrder.model = null;
			this.spOrder.location = null;
			this.spOrder.clientId = null;
			this.selectedSpOrderClient = null;
			this.selectedBrand = null;
			this.spOrder.latestEditingRecord = null;
		},
		resetSpOrderItemsTable: function () {
			this.spOrderItemsTableProps.sortDesc = true;
			this.spOrderItemsTableProps.currentPage = 1;
			this.spOrderItemsTableProps.itemsPerPage = 20;
			this.spOrderItemsTableProps.totalItems = 0;
			this.spOrderItemsTableProps.inputFilter.spOrderId = null
			this.spOrderItemsTableProps.textFilterTimeout = null;
			this.spOrderItemsTableProps.errorText = '';
		},
		resetSpOrderItemObject: function () {
			this.newSpOrderItem.id = null;
			this.newSpOrderItem.sp_order_id = null;
			this.newSpOrderItem.catalog_number = null;
			this.newSpOrderItem.title = null;
			this.newSpOrderItem.status = null;
			this.newSpOrderItem.estimated_delivery_date = null;
			this.newSpOrderItem.note = null;
			this.newSpOrderItem.in_house = false;
		},
		startProgress: function () {
			this.isLoading = true;
			this.okButton.disabled = true;
			this.okButton.progress = true;
		},
		stopProgress: function () {
			this.isLoading = false;
			this.okButton.disabled = false;
			this.okButton.progress = false;
		},
		showAlert: function (message, type, seconds) {
			if (message && type && seconds) {
				this.message.type = type;
				this.message.text = message;
				setTimeout(() => {
					this.showAlert();
				}, seconds * 1000);
			} else if (message && type) {
				this.message.type = type;
				this.message.text = message;
			} else if (message) {
				this.message.type = 'primary';
				this.message.text = message;
			} else {
				this.message.type = null;
				this.message.text = null;
			}
		},
		change_spOrderClientsSelect: function (client) {
			this.setSpOrderEditing();
			if (client) {
				if (client.id) {
					this.spOrder.clientId = client.id;
				} else {
					let defaultClient = this.getDefaultClient();
					let inputValue = client.first_name ?? client;
					let spaceIndex = inputValue.indexOf(' ');
					if(spaceIndex > -1) {
						defaultClient.firstName = inputValue.substring(0, spaceIndex)
						defaultClient.lastName = inputValue.substring(spaceIndex+1);
					} else {
						defaultClient.firstName = inputValue;
					}
					this.startProgress();
					this.$store.dispatch(SP_ORDER_CLIENT_CREATE, { client: defaultClient })
						.then((responseData) => {
							this.spOrder.clientId = responseData.client.id;
							this.stopProgress();
							this.$store.dispatch(SP_ORDER_CLIENTS_LIST_ALL)
								.catch(error => {
									console.error(error);
								});
						})
						.catch(error => {
							this.stopProgress();
							console.error(error);
							if(error.response && error.response.data && error.response.data.code) {
								this.showAlert('Napaka pri dodajanju stranke! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
							} else {
								this.showAlert('Napaka pri dodajanju stranke! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
							}
						});
				}
			} else {
				this.spOrder.clientId = null;
			}
		},
		getDefaultClient: function () {
			return {
				firstName: '',
				lastName: '',
				isCompany: false
			};
		},
		click_editClient: function () {
			let modalInput = {
				mode: 2,
				client: this.selectedSpOrderClient
			}
			this.$refs.spOrderClientCreateEditModal.showModal(modalInput);
		},
		emit_spOrderClientCreatedOrEdited: function (data) {
			this.selectedSpOrderClient = data.client;
			this.change_spOrderClientsSelect(data.client);
			const searchEl = this.$refs.vSelectClient.searchEl;
			if (searchEl) {
				searchEl.blur();
			}
			this.$emit('emit_spOrderChange');
		},
		emit_spOrderClientModalCancel: function () {
			if (!this.selectedSpOrderClient.id) {
				this.selectedSpOrderClient = null;
			}
		},
		filter_clientsSelect: (option, label, search) => {
			let s = search.toLowerCase();
			if (option.is_company) {
				let text = (option.company_title ? option.company_title.toLowerCase() : '') + ' ' + (option.email ? option.email.toLowerCase() : '');
				let t = (text.indexOf(s) > -1);
				return t;
			} else {
				let text = (option.first_name ? option.first_name.toLowerCase() : '') + ' ' + (option.last_name ? option.last_name.toLowerCase() : '');
				let t = (text.indexOf(s) > -1);
				return t;
			}
		},
		getClientSelectLabel: function(client, slot) {
			let label = '';
			if (!client) {
				return '';
			}
			if (client.is_company) {
				label += client.company_title ? client.company_title + ' ' : '';
				label += client.email ? '(' + client.email + ')' : '';
			} else {
				label += client.first_name ? client.first_name + ' ' : '';
				label += client.last_name ? client.last_name + ' ' : '';
				label += client.email ? '(' + client.email + ')' : '';
			}
			if (slot === 'selected-option' && label.length > 30) {
				return label.substring(0, 27) + '...';
			}
			return label;
		},
		spOrderItemsTable_items: function (ctx) {
			//this.spOrderItemsTableProps.isLoading = true;
			this.startProgress();
			return this.$store.dispatch(SP_ORDER_ITEMS_LIST_FILTERED, { ctx: ctx, tableProps: this.spOrderItemsTableProps })
				.then((responseData) => {
					this.stopProgress();
					//this.spOrderItemsTableProps.isLoading = false;
					this.spOrderItemsTableProps.items = responseData.spOrderItemsPagination.data.map(obj=> {
						let withNewPropertyes = ({ ...obj, edited: false });
						withNewPropertyes.status = this.spOrderItemsStatusOptions.find((item) => item.value === obj.status);
						return withNewPropertyes;
					});
					this.spOrderItemsTableProps.totalItems = responseData.spOrderItemsPagination.total;
					this.spOrderItemsTableProps.empty_text = 'Ni vnosov';
					return this.spOrderItemsTableProps.items;
				})
				.catch(error => {
					this.stopProgress();
					//this.spOrderItemsTableProps.isLoading = false;
					this.spOrderItemsTableProps.items = [];
					this.spOrderItemsTableProps.totalItems = 0;
					this.spOrderItemsTableProps.empty_text = 'Napaka pri pridobivanju postavk naročila';
					console.log(error);
					return [];
				});
		},
		click_spOrderItemCreate: function(data) {
			this.startProgress();
			this.newSpOrderItem.sp_order_id = this.spOrder.id;
			this.$store.dispatch(SP_ORDER_ITEM_CREATE, { spOrderItem: this.newSpOrderItem })
				.then((responseData) => {
					this.stopProgress();
					this.resetSpOrderItemObject();
					this.$refs.spOrderItemsTable.refresh();
					this.$emit('emit_spOrderChange', responseData);
					this.showAlert('Postavka ' + responseData.spOrderItem.id + ' uspešno dodana!', this.ALERT_TYPE_SUCCESS, 5);
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.showAlert('Napaka pri dodajanju postavke! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		},
		click_spOrderItemClear: function() {
			this.resetSpOrderItemObject();
		},
		click_enableEditing: function() {
			if (this.mode === this.mode_DETAILS3) {
				if (this.editingRecord.type) {
					this.startProgress();
					this.$store.dispatch(EDITING_RECORD_START, { editingRecord: this.editingRecord })
						.then((responseData) => {
							this.stopProgress();
							if (responseData.editingRecord) {
								this.$emit('emit_spOrderChange');
								this.editingRecord.id = responseData.editingRecord.id;
								this.mode = this.mode_EDIT2;
							} else {
								this.showAlert('Napaka pri začetku urejanja!', this.ALERT_TYPE_ERROR);
							}
						})
						.catch(error => {
							this.stopProgress();
							console.error(error);
							if(error.response && error.response.data && error.response.data.code) {
								this.showAlert('Napaka pri začetku urejanja! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
							} else {
								this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
							}
						});
				} else {
					this.mode = this.mode_EDIT2;
				}
			} else {
				this.click_spOrderCreate();
			}
		},
		click_spOrderItemEdit: function(data) {
			this.startProgress();
			this.$store.dispatch(SP_ORDER_ITEM_CREATE, { spOrderItem: data })
				.then((responseData) => {
					this.stopProgress();
					this.$refs.spOrderItemsTable.refresh();
					this.$emit('emit_spOrderChange', responseData);
					this.showAlert('Postavka ' + responseData.spOrderItem.id + ' uspešno urejena!', this.ALERT_TYPE_SUCCESS, 5);
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.showAlert('Napaka pri urejanju postavke! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		},
		click_spOrderItemCancel: function() {
			this.$refs.spOrderItemsTable.refresh();
		},
		click_spOrderItemRemove: function(data) {
			this.$bvModal.msgBoxConfirm('Želite izbrisati postavko #' + data.id + '?', {
				title: 'Potrdite brisanje postavke',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.startProgress();
					this.$store.dispatch(SP_ORDER_ITEM_DELETE, { id: data.id })
						.then((responseData) => {
							this.stopProgress();
							this.showAlert('Postavka ' + data.id + ' uspešno izbrisana!', this.ALERT_TYPE_SUCCESS, 5);
							this.$emit('emit_spOrderChange', responseData);
							this.$refs.spOrderItemsTable.refresh();
						})
						.catch(error => {
							this.stopProgress();
							this.isLoading = false;
							console.error(error);
							if(error.response && error.response.data && error.response.data.code) {
								this.$refs.toastr.e('Napaka pri brisanju postavke! (' + error.response.data.code + ')');
							} else {
								this.$refs.toastr.e('Napaka pri brisanju postavke! Prosimo poizkusite kasneje.');
							}
						});
				}
			}).catch(err => {
				console.error(err);
			});
		},
		setSpOrderItemEditing: function(data) {
			data.edited = true;
		},
		spOrderItemInHouseChange: function(data) {
			data.in_house = !data.in_house;
			if (data.in_house) {
				data.status = this.spOrderItemsStatusOptions.at(-1);
			} else {
				data.status = null;
			}
			this.click_spOrderItemEdit(data);
		},
		newSpOrderItemInHouseChange: function() {
			this.newSpOrderItem.in_house = !this.newSpOrderItem.in_house;
			if (this.newSpOrderItem.in_house) {
				this.newSpOrderItem.status = this.spOrderItemsStatusOptions.at(-1);
			} else {	
				this.newSpOrderItem.status = null;
			}
		},
		setSpOrderEditing: function() {
			this.isSpOrderEditing = true;
		},
		click_spOrderEditCancel: function() {
			this.startProgress();
			this.$store.dispatch(SP_ORDER_GET, { id: this.spOrder.id })
				.then((responseData) => {
					this.stopProgress();
					this.isSpOrderEditing = false;
					this.initSpOrder(responseData.spOrder);
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.showAlert('Napaka pri pridobivanju naročila! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
					} else {
						this.showAlert('Napaka pri pridobivanju naročila! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				}); 
		},
		isEditingSpOrderItemDisabled: function(data) {
			if (data.edited) {
				this.isSpOrderItemEditing = true;
				return false;
			} else if (this.spOrderItemsTableProps.items.some(item => item.edited === true)) {
				this.isSpOrderItemEditing = true;
				return true;
			}
			this.isSpOrderItemEditing = false;
			return false;
		},
		getEstimatedDeliveryDateLabel: function(item) {
			if (item.estimated_delivery_date != null) {
				let date = new Date(item.estimated_delivery_date).toLocaleDateString();
				let tommorow = new Date();
				tommorow = addDays(tommorow, 1);
				tommorow = tommorow.toLocaleDateString();
				if (date == tommorow) {
					return 'JUTRI';
				}
				return this.$options.filters.viewDate(item.estimated_delivery_date);
			}
			return 'Ni roka';
		},
		change_brandsSelect: function (brand) {
			this.setSpOrderEditing();
			if (brand) {
				this.spOrder.brandId = brand.id;
			} else {
				this.spOrder.brandId = null;
			}
		},
		filter_brandsSelect: (option, label, search) => {
			let s = search.toLowerCase();
			let text = option.title ? option.title.toLowerCase() : ''
			let t = text.indexOf(s) > -1;
			return t;
		},
		getLocationLabel: function(location, slot) {
			let label = location.label;
			if (slot === 'selected-option' && label.length > 18) {
				return label.substring(0, 15) + '...';
			}
			return label;
		},
		vozilaSelect_load: _.debounce((loading, search, context) => {
			loading(true);
			context.$store.dispatch(VEHICLES_LIST_FILTERED, search )
				.then((responseData) => {
					loading(false);
					context.vehicle_options = responseData.items;
				})
				.catch(error => {
					loading(false);
					if(error.response && error.response.data && error.response.data.code) {
						context.showAlert('Napaka pri pridobivanju vozil! (' + error.response.data.code + ')', context.ALERT_TYPE_ERROR);
					} else {
						context.showAlert('Napaka pri pridobivanju vozil! Prosimo poizkusite kasneje.', context.ALERT_TYPE_ERROR);
					}
				});
		}, 500),
		vehicleSelect_search: function (search, loading) {
			if (search && search !== '') {
				this.vozilaSelect_load(loading, search, this);
			}
		},
		vehicleSelect_change: function (value) {
			this.setSpOrderEditing();
			if (value) {
				if (value.ogrodje) {
					this.spOrder.vin = value.ogrodje;
					this.spOrder.model = value.model;
					this.spOrder.brandId = value.brand_id;
					this.selectedBrand = this.allVehicleBrands.find(brand => brand.id === value.brand_id);
				} else {
					this.spOrder.vin = value;
				}
			} else {
				this.spOrder.vin = null;
			}
		},
		newSpOrderItemEstimatedDateInput: function (value) {
		},
		copyToCliboard: function (value, prop) {
			navigator.clipboard.writeText(value);
			if (prop === 'spOrderNumber') {
				this.spOrderNumberCopied = true;
				setTimeout(() => {
					this.spOrderNumberCopied = false;
				}, 2000);
				this.vinCopied = false;
				this.registrationNumberCopied = false;
			} else if (prop === 'vin') {
				this.vinCopied = true;
				setTimeout(() => {
					this.vinCopied = false;
				}, 2000);
				this.spOrderNumberCopied = false;
				this.registrationNumberCopied = false;
			} else if (prop === 'registrationNumber') {
				this.registrationNumberCopied = true;
				setTimeout(() => {
					this.registrationNumberCopied = false;
				}, 2000);
				this.spOrderNumberCopied = false;
				this.vinCopied = false;
			}
		},
		spOrderItemStatusChange: function(data) {
			if (data.status === this.spOrderItemsStatusOptions.at(-1)) {
				data.in_house = true;
			} else {
				data.in_house = false;
			}
			this.click_spOrderItemEdit(data);
		},
		newSpOrderItemStatusChange: function() {
			if (this.newSpOrderItem.status === this.spOrderItemsStatusOptions.at(-1)) {
				this.newSpOrderItem.in_house = true;
			} else {
				this.newSpOrderItem.in_house = false;
			}
		},
		getStatusTitle: function (item) {
			if (item.status === this.spOrderItemsStatusOptions.at(-1)) {
				return this.$options.filters.viewDateTime(item.in_house_setted_at);
			}
			return '';
		},
		isCurrentlyEdited: function () {
			if (!this.spOrder.latestEditingRecord) return false;
			if (this.spOrder.latestEditingRecord.ended_at != null) return false;
			let now = new Date();
			let limit = new Date(this.spOrder.latestEditingRecord.started_at);
			let limitTime = addSeconds(limit, this.editingRecord.type.show_lock_length);
			if (now > limitTime) return false;
			return true;
		},
		isEditingDisabled: function () {
			if (!this.spOrder.latestEditingRecord) return false;
			if (this.spOrder.latestEditingRecord.ended_at != null) return false;
			if (this.spOrder.latestEditingRecord.started_by_user.id === this.profile.id) return false;
			let now = new Date();
			let limit = new Date(this.spOrder.latestEditingRecord.started_at);
			let limitTime = addSeconds(limit, this.editingRecord.type.lock_length);
			if (now > limitTime) return false;
			return true;
		},
		getEditingLockTitle: function () {
			return 'Naročilo je začel urejati ' + this.spOrder.latestEditingRecord.started_by_user.name + ' dne ' + this.$options.filters.viewDate(this.spOrder.latestEditingRecord.started_at) + ' ob ' + this.$options.filters.viewTime(this.spOrder.latestEditingRecord.started_at);
		},
		click_spOrderForceEdit: function () {
			this.$bvModal.msgBoxConfirm('Naročilo #' + this.spOrder.id + ' ureja ' + this.spOrder.latestEditingRecord.started_by_user.name + '! Želite vseeno urediti naročilo?', {
				title: 'Potrdite urejanje naročila ND',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.editingRecord.forced_edit = true;
					this.click_enableEditing();
				}
			}).catch(err => {
				console.error(err);
			})
		},
	},
	data: function () {
		return {
			settings,
			imagesTool,
			isLoading: false,
			title: '',
			okButton: {
				label: 'Shrani',
				progress: false,
				disabled: false
			},
			message: {
				type: null,
				text: null
			},
			ALERT_TYPE_SUCCESS: 'success',
			ALERT_TYPE_ERROR: 'danger',
			mode_UNKNOWN0: 0,
			mode_CREATE1: 1,
			mode_EDIT2: 2,
			mode_DETAILS3: 3,
			mode: 0,
			spOrder: {
				id: null,
				date: null,
				spOrderNumber: null,
				department: null,
				vin: null,
				registrationNumber: null,
				location: null,
				brandId: null,
				model: null,
				clientId: null,
				latestEditingRecord: null
			},
			selectedSpOrderClient: null,
			spOrderItemsTableProps: {
				sortDesc: true,
				currentPage: 1,
				itemsPerPage: 20,
				totalItems: 0,
				isLoading: false,
				options_numRowsOnPage: [
					{ value: 20, text: '20' },
					{ value: 100, text: '100' },
					{ value: 200, text: '200' }
				],
				empty_text: '',
				empty_filtered_text: 'Ni zadetkov',
				inputFilter: {
					spOrderId: null
				},
				textFilterTimeout: null,
				errorText: ''
			},
			newSpOrderItem: {
				id: null,
				sp_order_id: null,
				catalog_number: null,
				title: null,
				status: null,
				estimated_delivery_date: null,
				note: null,
				in_house: false
			},
			isSpOrderItemEditing: false,
			permissionCheck: PermissionCheck,
			selectedBrand : null,
			vehicle_options: [],
			isSpOrderEditing: false,
			spOrderOkButton: {
				title: 'Dodaj'
			},
			registrationNumberCopied: false,
			vinCopied: false,
			spOrderNumberCopied: false,
			editingRecord: {
				id: null,
				type: null,
				object_id: null,
				data_at_start: null,
				forced_edit: false
			}
		}
	},
	watch: {
	},
	computed: {
		profile() {
			return this.$store.getters.getProfile
		},
		modalSize () {
			return 'xl';
		},
		allSpOrderClients: function() {
			if (this.$store.getters.getAllSpOrderClients) {
				return this.$store.getters.getAllSpOrderClients;
			} else {
				this.$store.dispatch(SP_ORDER_CLIENTS_LIST_ALL)
					.then((responseData) => {
						console.log(responseData);
						return responseData.clients;
					})
					.catch(error => {
						console.error(error);
					});
			}
		},
		allVehicleBrands: function() {
			if (this.$store.getters.getAllVehicleBrands) {
				return this.$store.getters.getAllVehicleBrands;
			} else {
				this.$store.dispatch(VEHICLE_BRANDS_LIST_ALL)
					.then((responseData) => {
						console.log(responseData);
						return responseData.vehicleBrands;
					})
					.catch(error => {
						console.error(error);
					});
			}
		},
		editingDisabled () {
			if (this.mode === this.mode_DETAILS3) {
				return true;
			}
			return false;
		},
		showSpOrderItemsTableNav () {
			if (this.spOrderItemsTableProps.totalItems > this.spOrderItemsTableProps.itemsPerPage) {
				return true;
			}
			return false;
		},
		modalOkButtonData () {
			if (this.mode === this.mode_CREATE1) {
				return {
					label: 'Dodaj',
					title: 'Dodaj naročilnico'
				}
			} else if (this.mode === this.mode_EDIT2) {
				if (this.isSpOrderItemEditing) {
					return {
						label: 'Končaj urejanje',
						title: 'Najprej shranite/prekličite spremembe na postavkah'
					}
				}
				return {
					label: 'Končaj urejanje',
					title: 'Končaj urejanje in shrani naročilnico'
				}
			} else if (this.mode === this.mode_DETAILS3) {
				return {
					label: 'Uredi',
					title: 'Uredi'
				}
			}
			return {
				label: '',
				title: ''
			};
		},
		spOrderItemsStatusOptions () {
			return this.settings.spOrderItemsStatusOptions.filter(function(obj) {
				return obj.value !== '#';
			});
		},
		spOrderItemsTableFields: function () {
			let fields = [];
			fields.push({ key: 'catalog_number', label: 'Kataloška št. ND', sortable: true, class: 'tdSpOrderItemCatalogNumber' });
			fields.push({ key: 'title', label: 'Naziv ND', sortable: true, class: 'tdSpOrderItemTitle' });
			fields.push({ key: 'note', label: 'Opomba', sortable: true, class: 'tdSpOrderItemNote' });
			fields.push({ key: 'status', label: 'Status', sortable: true, class: this.mode === this.mode_EDIT2 ? 'tdSpOrderItemStatus' : 'tdSpOrderItemStatusDetails' });
			fields.push({ key: 'estimated_delivery_date', label: 'Predvidena dobava', sortable: true, class: 'tdSpOrderItemEstimatedDeliveryDate' });
			fields.push({ key: 'in_house', label: 'ND v hiši', sortable: true, class: 'tdSpOrderItemInHouse' });
			if (this.mode === this.mode_EDIT2) {
				fields.push({ key: 'actions', label: '', sortable: false, class: 'tdSpOrderItemActions' });
			}
			return fields;
		},
		spOrderItemsTableClass () {
			if (this.mode == this.mode_EDIT2) {
				return 'mb-1 spOrderItemsTable';
			}
			return 'mb-1';
		}
	},
	created () {
	}
}
</script>

<style scoped>
.ma_pos {
	text-align: end;
}
.addingSpOrderItemsRow {
	background-color: #B9BABC;
}
.inTableInput {
	margin-bottom: 0px !important;
}
.clientEditButton {
	margin-top: 2rem;
	margin-left: 0px;
	padding-left: 0px;
}
.copyButton {
	margin-top: 2rem;
	margin-left: 0rem;
}
.copyButtonClicked {
	margin-top: 0.7rem;
	margin-left: 0rem;
}
</style>
<style>
.tdSpOrderItemCatalogNumber {
	width: 10.5rem;
	min-width: 10.5rem;
}
.tdSpOrderItemTitle {
	width: 12rem;
}
.tdSpOrderItemStatus {
	width: 13rem;
	min-width: 13rem;
	max-width: 13rem;
}
.tdSpOrderItemStatusDetails {
	width: 8rem;
	min-width: 8rem;
	max-width: 8rem;
}
.tdSpOrderItemEstimatedDeliveryDate {
	width: 6rem;
	min-width: 6rem;
	max-width: 6rem;
}
.tdSpOrderItemNote {
	width: 2rem;
}
.tdSpOrderItemInHouse {
	width: 4rem;
	max-width: 4rem;
}
.tdSpOrderItemActions {
	width: 6rem;
	min-width: 6rem;
}
div.modal .modal-dialog.modal-1600 {
  max-width: 1600px !important;
}
.spOrderItemsTable {
	min-height: 350px;
}
</style>