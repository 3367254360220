import { render, staticRenderFns } from "./DatumFilterDropdown.vue?vue&type=template&id=2fba4752&scoped=true&"
import script from "./DatumFilterDropdown.vue?vue&type=script&lang=js&"
export * from "./DatumFilterDropdown.vue?vue&type=script&lang=js&"
import style0 from "./DatumFilterDropdown.vue?vue&type=style&index=0&id=2fba4752&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fba4752",
  null
  
)

export default component.exports