<script>
import { format, addDays} from 'date-fns';

export default {
	props: {
		default_label: {
			type: String,
			required: false
		},
		value: {
			required: false
		},
		disabled: {
			required: false
		}
	},
	methods: {
		click_tomorrow: function (evt) {
			this.is_custom = true;
			this.title = 'JUTRI';
			let start = new Date();
			start = addDays(start, 1);
			this.selectedDate = start;
			this.selected();
		},
		click_empty: function (evt) {
			this.is_custom = true;
			this.title = 'Ni roka';
			this.selectedDate = null;
			this.selected();
		},
		selected: function (env) {
			if (this.selectedDate && !this.is_custom) {
				this.is_smalltext_class = false;
				this.title = format(this.selectedDate, 'dd.MM.yyyy');
			}
			this.is_custom = false;
			if (this.selectedDate) {
				this.$emit('input', this.selectedDate.toISOString());
			}
			this.$emit('input', this.selectedDate);
			this.$refs.dropdown.hide();
		}
	},
	computed: {
		selectedTitle () {
			if (this.title) {
				return this.title;
			} else if (this.default_label) {
				return this.default_label;
			} else {
				return this.default_label_empty;
			}
		}
	},
	data: function () {
		return {
			title: null,
			selectedDate: null,
			default_label_empty: 'Ni roka',
			is_custom: false
		}
	},
	created () {
	}
}
</script>
<template>
	<b-dropdown :disabled="disabled" variant="primary" :text="selectedTitle" ref="dropdown" class="estimatedDateInput">
		<b-dropdown-form class="date-dd">
			<b-row>
				<b-col sm="12" class="ml-2">
					<v-date-picker
						v-model="selectedDate"
						is-inline
						locale="sl-SI"
						@input="selected"/>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="1"></b-col>
				<b-col align-self="end" sm="4" class="ml-2">
					<b-button variant="warning" class="mt-2 t" size="sm" @click.prevent="click_tomorrow">Jutri</b-button>
				</b-col>
				<b-col sm="1"></b-col>
				<b-col sm="4">
					<b-button variant="danger" class="mt-2 t" size="sm" @click.prevent="click_empty">Ni roka</b-button>
				</b-col>
				<b-col sm="1"></b-col>
			</b-row>
		</b-dropdown-form>
	</b-dropdown>
</template>
<style scoped>
.date-dd {
	width: 17rem!important;
}
.estimatedDateInput {
	width: 7rem;
	max-width: 7rem;
	min-width: 7rem;
}
</style>