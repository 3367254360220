<template>
	<div class="animated fadeIn">
		<b-card>
			<b-tabs v-model="selectedTabIndex">
				<b-tab title="Seznam naročil" class="pt-2" >
					<b-card>
						<vue-loading :active.sync="spOrdersTableProps.isLoading" :is-full-page="false"/>
						<vue-loading :active.sync="isLoadingDeleting" :is-full-page="false"/>
						<div slot="header">
							<div class="card-header-actions">
								<CInputCheckbox :checked.sync="spOrdersTableProps.inputFilter.showArchived" @change="click_spOrdersTableRefresh" label="Prikaži arhivirane" inline/>
								<CLink title="Osveži tabelo" href="#" class="card-header-action btn-setting" @click.stop="click_spOrdersTableRefresh">
									<CIcon name="cil-loop-circular" class="mb-1"/>
								</CLink>
								<CLink title="Ponastavi filtre" href="#" class="card-header-action btn-setting" @click.stop="click_spOrdersTableResetFilters">
									<CIcon name="cil-x" class="mb-1"/>
								</CLink>
								<CLink v-if="permissionCheck.can('sp-orders-write')" title="Dodaj naročilo" href="#" class="card-header-action btn-setting" @click.stop="click_spOrderCreate">
									<CIcon name="cil-plus" class="mb-1"/>
								</CLink>
							</div>
						</div>
						<b-table
							class="spOrdersTable"
							ref="spOrdersTable"
							responsive
							show-empty
							hover
							:empty-text="spOrdersTableProps.empty_text"
							:empty-filtered-text="spOrdersTableProps.empty_filtered_text"
							:fields="spOrdersTableFields"
							:current-page="spOrdersTableProps.currentPage"
							:per-page="spOrdersTableProps.itemsPerPage"
							:items="spOrdersTable_items"
							:sort-by.sync="spOrdersTableProps.sortBy"
							:sort-desc.sync="spOrdersTableProps.sortDesc"
							@row-clicked="rowClicked"
							:tbody-tr-class="rowClass"
							@sort-changed="spOrdersSortChanged">
							<template slot="top-row">
									<td><datum-filter-dropdown ref="spOrderDateFilter" v-model="spOrdersTableProps.inputFilter.date" @input="spOrdersTableFilter_change" v-bind:noFutureValues="true"/></td>
									<td><datum-filter-dropdown ref="spOrderUpdatedAtFilter" v-model="spOrdersTableProps.inputFilter.updatedAt" @input="spOrdersTableFilter_change" v-bind:noFutureValues="true"/></td>
									<td v-if="!permissionCheck.is('klepar')">
										<v-select
											v-model="filteringUser"
											:options="allUsersWithSpOrdersWritePermission"
											label="name"
											@input="change_usersFilterSelect"
											placeholder="Iz."
											:searchable="false">
											<template slot="option" slot-scope="option">
												{{ option.name }}
											</template> 
											<template slot="selected-option" slot-scope="option">
												{{ option.name }}
											</template>
										</v-select>
									</td>
									<td><sp-order-department-filter ref="spOrderDepartmentFilter" @change="change_spOrderDepartmentFilter"/></td>
									<td><b-form-input v-model="spOrdersTableProps.inputFilter.spOrderNumber" type="text" @input="spOrdersTableTextFilter_change"/></td>
									<td>
										<v-select
											v-model="filteringClient"
											:options="allSpOrderClients"
											:filter-by="filter_clientsFilterSelect"
											label="first_name"
											@input="change_clientsFilterSelect"
											placeholder="Izberi stranko ...">
											<template slot="no-options">Stranka ne obstaja</template>
											<template slot="option" slot-scope="option">
												{{ getClientFilterSelectLabel(option )}}
											</template>
											<template slot="selected-option" slot-scope="option">
												{{ getClientFilterSelectLabel(option, 'selected-option')}}
											</template>
										</v-select>
									</td>
									<td><b-form-input v-model="spOrdersTableProps.inputFilter.registrationNumber" type="text" @input="spOrdersTableTextFilter_change"/></td>
									<td>
										<v-select
											v-model="spOrdersTableProps.inputFilter.vehicleId"
											label="ogrodje"
											:options="vehicle_options"
											@input="vehicleSelect_change($event)"
											@search="vehicleSelect_search"
											placeholder="Izberi vozilo ...">
											<template slot="no-options">Vnesite VIN številko vozila</template>
											<template slot="option" slot-scope="option">
												<div v-if="option.id">{{ option.ogrodje }}</div>
											</template>
										</v-select>
									</td>
									<td>
										<sp-order-brand-filter ref="spOrderBrandFilter" @change="change_spOrderBrandFilter"/>
									</td>
									<td><b-form-input v-model="spOrdersTableProps.inputFilter.model" type="text" @input="spOrdersTableTextFilter_change"/></td>
									<td>
										<sp-order-location-filter ref="spOrderLocationFilter" @change="change_spOrderLocationFilter"/>
									</td>
									<td>
										<sp-order-status-filter ref="spOrderStatusFilter" @change="change_spOrderStatusFilter"/>
									</td>
									<td><b-form-input placeholder="Kataloška št." v-model="spOrdersTableProps.inputFilter.spOrderItemCatalogNumber" type="text" @input="spOrdersTableTextFilter_change"/></td>
							</template>
							<template v-slot:cell(actions)="data">
								<div v-if="permissionCheck.can('sp-orders-write')">
									<b-button v-if="editingRecordType && isCurrentlyEdited(data.item)" :disabled="isEditingDisabled(data.item)" :title="getEditingLockTitle(data.item)" variant="primary" @click.stop="click_spOrderForceEdit(data.item)" size="sm" class="mr-2">
										<CIcon name="cil-lock-locked"/>
									</b-button>
									<div v-else>
										<b-button title="Uredi naročilo" variant="primary" @click.stop="click_spOrderEdit(data.item)" size="sm" class="mr-2">
											<CIcon name="cil-pencil"/>
										</b-button>
										<b-button v-if="permissionCheck.is('admin')" title="Izbriši naročilo" variant="danger" @click.stop="click_spOrderDelete(data.item)" size="sm" class="mr-2">
											<CIcon name="cil-trash"/>
										</b-button>
										<b-button v-if="(permissionCheck.is('klepar') || permissionCheck.is('sprejemnik')) && data.item.archived_at == null" title="Arhiviraj naročilo" variant="warning" @click.stop="click_spOrderArchive(data.item)" size="sm" class="mr-2">
											<CIcon name="cil-briefcase"/>
										</b-button>
										<b-button v-if="(permissionCheck.is('klepar') || permissionCheck.is('sprejemnik')) && data.item.archived_at != null" title="Prenesi naročilo iz arhiva" variant="warning" @click.stop="click_spOrderUnarchive(data.item)" size="sm" class="mr-2">
											<CIcon name="cil-recycle"/>
										</b-button>
									</div>
								</div>
							</template>
							<template v-slot:cell(date)="data">
								{{ data.item.date | viewDate }}
							</template>
							<template v-slot:cell(updated_at)="data">
								{{ data.item.updated_at | viewDateTime }}
							</template>
							<template v-slot:cell(id)="data">
								{{ data.item.id }}
								<b-badge v-if="lastEditedWarning(data.item)" :title="lastEditedWarning(data.item).title" :variant="lastEditedWarning(data.item).variant">
									<CIcon name="cil-warning"/>
								</b-badge>
							</template>
							<template v-slot:cell(created_by_user_name)="data">
								{{ data.item.user_created.name }}
							</template>
							<template v-slot:cell(client_name)="data">
								{{ getClientLabel(data.item.client) }}
							</template>
							<template v-slot:cell(vin)="data">
								<div v-if="data.item.vehicle">{{ data.item.vehicle.ogrodje }}</div>
							</template>
							<template v-slot:cell(brand)="data">
								<div v-if="data.item.brand">
									<div v-if="data.item.brand.logo" :title="data.item.brand.title" :style="imagesTool.vehicleBrandImage(data.item.brand.logo, 1.8)"></div>
									<CIcon v-else :title="data.item.brand.title" :style="imagesTool.vehicleBrandIcon(3)" name="cil-car-alt"/>
								</div>
							</template>
							<template v-slot:cell(status)="data">
								<b-badge v-if="data.item.lastSpOrderItemStatus" :variant="getSpOrderItemStatus(data.item).variant">{{ getSpOrderItemStatus(data.item).label }}</b-badge>
								<b-badge v-else variant="light">V pripravi</b-badge>
							</template>
						</b-table>
						<nav>
							<b-row>
								<b-col></b-col>
								<b-col cols=2>
									<a class="page-link rows_total float-right">Št. vrstic: {{ spOrdersTableProps.totalItems }}</a>
								</b-col>
							</b-row>
						</nav>
					</b-card>
				</b-tab>
				<b-tab title="Dolgovanje" class="pt-2" >
					<b-card>
						<vue-loading :active.sync="spOrderItemsTableProps.isLoading" :is-full-page="false"/>
						<div slot="header">
							<div class="card-header-actions">
								<CLink title="Osveži tabelo" href="#" class="card-header-action btn-setting" @click.stop="click_spOrderItemsTableRefresh">
									<CIcon name="cil-loop-circular" class="mb-1"/>
								</CLink>
								<CLink title="Prenesi PDF" href="#" class="card-header-action" @click.stop="downloadSpOrderItemsDebt('pdf')">
									<CIcon name="cil-vertical-align-bottom" class="mb-1"/>PDF
								</CLink>
								<CLink title="Prenesi CSV" href="#" class="card-header-action" @click.stop="downloadSpOrderItemsDebt('csv')">
									<CIcon name="cil-vertical-align-bottom" class="mb-1"/>CSV
								</CLink>
							</div>
						</div>
						<b-table
							v-if="selectedTabIndex === 1"
							class="spOrderItemsTable"
							ref="spOrderItemsTable"
							responsive
							show-empty
							hover
							:empty-text="spOrderItemsTableProps.empty_text"
							:empty-filtered-text="spOrderItemsTableProps.empty_filtered_text"
							:fields="spOrderItemsTableFields"
							:current-page="spOrderItemsTableProps.currentPage"
							:per-page="spOrderItemsTableProps.itemsPerPage"
							:items="spOrderItemsTable_items"
							:sort-by.sync="spOrderItemsTableProps.sortBy"
							:sort-desc.sync="spOrderItemsTableProps.sortDesc"
							@row-clicked="spOrderItemRowClicked">
							<template slot="top-row">
							</template>
							<template v-slot:cell(sp_order_date)="data">
								{{ data.item.sp_order.date | viewDate }}
							</template>
							<template v-slot:cell(estimated_delivery_date)="data">
								<div v-if="data.item.estimated_delivery_date">
									{{ data.item.estimated_delivery_date | viewDate }}
								</div>
							</template>
							<template v-slot:cell(sp_order_number)="data">
								{{ data.item.sp_order.sp_order_number }}
							</template>
							<template v-slot:cell(brand)="data">
								<div v-if="data.item.sp_order.brand">
									<div v-if="data.item.sp_order.brand.logo" :title="data.item.sp_order.brand.title" :style="imagesTool.vehicleBrandImage(data.item.sp_order.brand.logo, 1.8)"></div>
									<CIcon v-else :title="data.item.sp_order.brand.title" :style="imagesTool.vehicleBrandIcon(3)" name="cil-car-alt"/>
								</div>
							</template>
							<template v-slot:cell(client_name)="data">
								{{ getClientLabel(data.item.sp_order.client) }}
							</template>
						</b-table>
						<nav>
							<b-row>
								<b-col>
									<b-pagination :total-rows="spOrderItemsTableProps.totalItems" :per-page="spOrderItemsTableProps.itemsPerPage" v-model="spOrderItemsTableProps.currentPage" prev-text="Prejšnja" next-text="Naslednja" hide-goto-end-buttons />
								</b-col>
								<b-col cols=2>
									<a class="page-link rows_total float-right">Št. vrstic: {{ spOrderItemsTableProps.totalItems }}</a>
								</b-col>
								<b-col cols=1>
									<b-form-select v-model="spOrderItemsTableProps.itemsPerPage" :options="spOrderItemsTableProps.options_numRowsOnPage"></b-form-select>
								</b-col>
							</b-row>
						</nav>
					</b-card>
				</b-tab>
			</b-tabs>
		</b-card>
		<vue-toastr ref="toastr"></vue-toastr>
		<sp-order-create-edit-modal ref="spOrderCreateEditModal" @emit_spOrderChange="emit_spOrderChange" @emit_spOrderModalClosed="emit_spOrderModalClosed" @emit_spOrderEditingRecordStartError="emit_spOrderEditingRecordStartError" @emit_spOrderSetView="emit_spOrderSetView"/>
	</div>
</template>

<script>
import PermissionCheck from '@/tools/permission-check';
import imagesTool from '@/tools/images';
import settings from '@/settings.js';
import SpOrderCreateEditModal from '@/views/modals/SpOrderCreateEdit';
import { 
	SP_ORDERS_LIST_FILTERED, 
	SP_ORDER_DELETE, 
	SP_ORDER_ARCHIVE, 
	SP_ORDER_UNARCHIVE, 
	USER_SP_ORDERS_BRANDS_FILTER,
	USER_SP_ORDERS_DEPARTMENT_FILTER,
	USER_SP_ORDERS_LOCATION_FILTER,
	USER_SP_ORDERS_STATUS_FILTER,
	USER_SP_ORDERS_SORTING
} from "@/store/actions/spOrders";
import { SP_ORDER_CLIENTS_LIST_ALL } from "@/store/actions/spOrderClients";
import { VEHICLES_LIST_FILTERED } from "@/store/actions/vehicles";
import { VEHICLE_BRANDS_LIST_ALL } from "@/store/actions/vehicleBrands";
import { USERS_LIST_ALL_WITH_SP_ORDERS_WRITE_PERMISSION } from "@/store/actions/users";
import { SP_ORDER_ITEMS_LIST_FILTERED, SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT } from "@/store/actions/spOrderItems";
import fbApp from "@/firebase";
import { getDatabase, ref, onValue, set } from "firebase/database";
import DatumFilterDropdown from '@/views/components/DropdownFilters/DatumFilterDropdown';
import SpOrderBrandFilter from '@/views/components/DropdownFilters/SpOrderBrandFilter';
import SpOrderStatusFilter from '@/views/components/DropdownFilters/SpOrderStatusFilter';
import SpOrderLocationFilter from '@/views/components/DropdownFilters/SpOrderLocationFilter';
import SpOrderDepartmentFilter from '@/views/components/DropdownFilters/SpOrderDepartmentFilter';
import { addSeconds } from 'date-fns';

const db = getDatabase(fbApp);
const spOrdersRefresh = ref(db, 'narocanje');
export default {
	name: 'SpOrders',
	components: {
		'sp-order-create-edit-modal': SpOrderCreateEditModal,
		'datum-filter-dropdown': DatumFilterDropdown,
		'sp-order-brand-filter': SpOrderBrandFilter,
		'sp-order-status-filter': SpOrderStatusFilter,
		'sp-order-location-filter': SpOrderLocationFilter,
		'sp-order-department-filter': SpOrderDepartmentFilter
	},
	data () {
		return {
			settings,
			imagesTool,
			spOrdersTableProps: {
				sortDesc: true,
				sortBy: '',
				currentPage: 1,
				itemsPerPage: 100,
				totalItems: 0,
				isLoading: false,
				options_numRowsOnPage: [
					{ value: 50, text: '50' },
					{ value: 100, text: '100' },
					{ value: 200, text: '200' },
					{ value: 1000, text: '1000' }
				],
				empty_text: '',
				empty_filtered_text: 'Ni zadetkov',
				inputFilter: {
					id: null,
					date: null,
					updatedAt: null,
					createdBy: null,
					spOrderNumber: null,
					clientId: null,
					registrationNumber: null,
					vehicleId: null,
					brands: null,
					location: null,
					status: null,
					spOrderItemCatalogNumber: null,
					showArchived: false
				},
				textFilterTimeout: null,
				errorText: ''
			},
			isLoadingDeleting: false,
			permissionCheck: PermissionCheck,
			filteringClient: null,
			vehicle_options: [],
			allUsersWithSpOrdersWritePermission: [],
			filteringUser: null,
			currenlyEditSpOrder: null,
			editingRecordType: null,
			updateSpOrdersTable: false,
			spOrderItemsTableProps: {
				sortDesc: true,
				currentPage: 1,
				itemsPerPage: 50,
				totalItems: 0,
				isLoading: false,
				options_numRowsOnPage: [
					{ value: 50, text: '50' },
					{ value: 100, text: '100' },
					{ value: 200, text: '200' },
					{ value: 1000, text: '1000' }
				],
				empty_text: '',
				empty_filtered_text: 'Ni zadetkov',
				inputFilter: {},
				debtOnly: true,
				textFilterTimeout: null,
				errorText: '',
			},
			updateSpOrderItemsTable: false,
			selectedTabIndex: 0
		}
	},
	computed: {
		profile() {
			return this.$store.getters.getProfile
		},
		allSpOrderClients: function() {
			if (this.$store.getters.getAllSpOrderClients) {
				return this.$store.getters.getAllSpOrderClients;
			} else {
				this.$store.dispatch(SP_ORDER_CLIENTS_LIST_ALL)
					.then((responseData) => {
						console.log(responseData);
						return responseData.clients;
					})
					.catch(error => {
						console.error(error);
					});
			}
		},
		allVehicleBrands: function() {
			if (this.$store.getters.getAllVehicleBrands) {
				return this.$store.getters.getAllVehicleBrands;
			} else {
				this.$store.dispatch(VEHICLE_BRANDS_LIST_ALL)
					.then((responseData) => {
						console.log(responseData);
						return responseData.vehicleBrands;
					})
					.catch(error => {
						console.error(error);
					});
			}
		},
		spOrdersTableFields: function () {
			let fields = [];
			fields.push({ key: 'date', label: 'Datum', sortable: true, class: 'tdSpOrderDate' });
			fields.push({ key: 'updated_at', label: 'Zadnja sprememba', sortable: true, class: 'tdSpOrderDate' });
			if (!this.permissionCheck.is('klepar')) {
				fields.push({ key: 'created_by_user_name', label: 'Naročil', sortable: false, class: 'tdSpOrderCreatedByUserName' });
			}
			fields.push({ key: 'department', label: 'Oddelek', sortable: false, class: 'tdSpOrderDepartment' });
			fields.push({ key: 'sp_order_number', label: 'Št. DN', sortable: false, class: 'tdSpOrderNumber' });
			fields.push({ key: 'client_name', label: 'Stranka', sortable: false, class: 'tdSpOrderClientName' });
			fields.push({ key: 'registration_number', label: 'Reg. št.', sortable: false, class: 'tdSpOrderRegistrationNumber' });
			fields.push({ key: 'vin', label: 'Šasija', sortable: false, class: 'tdSpOrderVin' });
			fields.push({ key: 'brand', label: 'Znamka', sortable: false, class: 'tdSpOrderBrand' });
			fields.push({ key: 'model', label: 'Model', sortable: false, class: 'tdSpOrderModel' });
			fields.push({ key: 'location', label: 'Lokacija', sortable: false, class: 'tdSpOrderLocation' });
			fields.push({ key: 'status', label: 'Status', sortable: false, class: 'tdSpOrderStatus' });
			fields.push({ key: 'actions', label: '#', sortable: false, class: 'tdSpOrdersActions text-right' });
			return fields;
		},
		spOrderItemsTableFields: function () {
			let fields = [];
			fields.push({ key: 'catalog_number', label: 'Kataloška št. ND', sortable: true, class: 'tdSpOrderItemCatalogNumber' });
			fields.push({ key: 'brand', label: 'Znamka', sortable: false, class: 'tdSpOrderBrand' });
			fields.push({ key: 'title', label: 'Naziv ND', sortable: true, class: 'tdSpOrderItemTitle' });
			fields.push({ key: 'sp_order_date', label: 'Datum naročila', sortable: false, class: 'tdSpOrderDate' });
			fields.push({ key: 'estimated_delivery_date', label: 'Predvidena dobava', sortable: false, class: 'tdSpOrderDate' });
			fields.push({ key: 'client_name', label: 'Stranka', sortable: false, class: 'tdSpOrderClientName' });
			fields.push({ key: 'sp_order_number', label: 'Št. DN', sortable: false, class: 'tdSpOrderDate' });
			return fields;
		}
	},
	methods: {
		click_spOrdersTableRefresh: function () {
			this.spOrdersTableProps.itemsPerPage = this.spOrdersTableProps.options_numRowsOnPage[1].value;
			this.$refs.spOrdersTable.refresh();
		},
		click_spOrdersTableResetFilters: function () {
			this.spOrdersTableProps.inputFilter.id = null;
			this.spOrdersTableProps.inputFilter.date = null;
			this.spOrdersTableProps.inputFilter.createdBy = null;
			this.spOrdersTableProps.inputFilter.spOrderNumber = null;
			this.spOrdersTableProps.inputFilter.department = null;
			this.spOrdersTableProps.inputFilter.model = null;
			this.spOrdersTableProps.inputFilter.clientId = null;
			this.spOrdersTableProps.inputFilter.registrationNumber = null;
			this.spOrdersTableProps.inputFilter.vehicleId = null;
			// this.spOrdersTableProps.inputFilter.brands = null;
			this.spOrdersTableProps.inputFilter.location = null;
			this.spOrdersTableProps.inputFilter.status = null;
			this.spOrdersTableProps.inputFilter.spOrderItemCatalogNumber = null;
			this.spOrdersTableProps.inputFilter.showArchived = false;
			this.filteringClient = null;
			this.filteringUser = null;
			this.vehicle_options = [];
			// this.$refs.spOrderBrandFilter.click_all();
			this.$refs.spOrderDateFilter.click_clear();
			this.$refs.spOrderUpdatedAtFilter.click_clear();
			this.$refs.spOrderStatusFilter.click_all();
			this.$refs.spOrderLocationFilter.click_all();
			this.$refs.spOrderDepartmentFilter.click_all();
			this.click_spOrdersTableRefresh();
		},
		spOrdersTable_items: function (ctx) {
			this.spOrdersTableProps.isLoading = true;
			return this.$store.dispatch(SP_ORDERS_LIST_FILTERED, { ctx: ctx, tableProps: this.spOrdersTableProps })
				.then((responseData) => {
					this.spOrdersTableProps.isLoading = false;
					this.spOrdersTableProps.items = responseData.spOrdersPagination.data;
					this.spOrdersTableProps.totalItems = responseData.spOrdersPagination.total;
					this.spOrdersTableProps.empty_text = 'Ni vnosov';
					if (this.currenlyEditSpOrder) {
						let upToDateSpOrder = this.spOrdersTableProps.items.find(el => el.id == this.currenlyEditSpOrder.id);
						this.$refs.spOrderCreateEditModal.updateModalData(upToDateSpOrder);
						this.currenlyEditSpOrder = upToDateSpOrder;
					}
					this.editingRecordType = responseData.editingRecordType ?? null;
					return responseData.spOrdersPagination.data;
				})
				.catch(error => {
					this.spOrdersTableProps.isLoading = false;
					this.spOrdersTableProps.items = [];
					this.spOrdersTableProps.totalItems = 0;
					this.spOrdersTableProps.empty_text = 'Napaka pri pridobivanju naročil';
					console.log(error);
					this.editingRecordType = null;
					return [];
				});
		},
		spOrdersTableTextFilter_change: function (value) {
			if (this.spOrdersTableProps.textFilterTimeout !== null) {
				clearTimeout(this.spOrdersTableProps.textFilterTimeout);
			}
			this.spOrdersTableProps.textFilterTimeout = setTimeout(() => {
				this.click_spOrdersTableRefresh();
			}, settings.filterTimeoutLength);
		},
		spOrdersTableFilter_change: function (value) {
			this.click_spOrdersTableRefresh();
		},
		rowClicked (row) {
			this.currenlyEditSpOrder = row;
			let modalInput = {
				mode: 3, // details mode
				spOrder: row,
				editingRecordType: this.editingRecordType
			}
			this.$refs.spOrderCreateEditModal.showModal(modalInput);
		},
		click_spOrderCreate: function () {
			let modalInput = {
				mode: 1, // normal mode
				spOrder: null,
				editingRecordType: this.editingRecordType
			}
			this.$refs.spOrderCreateEditModal.showModal(modalInput);
		},
		click_spOrderEdit: function (data) {
			this.showEditSpOrderModal(data);
		},
		click_spOrderForceEdit: function (data) {
			this.$bvModal.msgBoxConfirm('Naročilo #' + data.id + ' ureja ' + data.latest_editing_record.started_by_user.name + '! Želite vseeno urediti naročilo?', {
				title: 'Potrdite urejanje naročila ND',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.showEditSpOrderModal(data, true);
				}
			}).catch(err => {
				console.error(err);
			})
		},
		showEditSpOrderModal: function (data, forced) {
			this.currenlyEditSpOrder = data;
			let modalInput = {
				mode: 2, // normal mode
				spOrder: data,
				editingRecordType: this.editingRecordType
			}
			if (this.editingRecordType) {
				modalInput.forced_edit = forced ?? null;
			}
			this.$refs.spOrderCreateEditModal.showModal(modalInput);
		},
		emit_spOrderChange: function (data) {
			this.$refs.spOrdersTable.refresh();
			this.updateSpOrdersTable = false;
			if (this.selectedTabIndex === 1) {
				this.$refs.spOrderItemsTable.refresh();
				this.updateSpOrderItemsTable = false;
			}
			set(spOrdersRefresh, {
				updated_on: new Date().getTime()
			});
		},
		emit_spOrderModalClosed: function () {
			this.currenlyEditSpOrder = null;
		},
		click_spOrderDelete: function (data) {
			this.$bvModal.msgBoxConfirm('Želite izbrisati naročilo ND #' + data.id + '?', {
				title: 'Potrdite brisanje naročila ND',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.isLoadingDeleting = true;
					this.$store.dispatch(SP_ORDER_DELETE, { id: data.id })
						.then((responseData) => {
							this.isLoadingDeleting = false;
							this.$refs.toastr.s('Naročilo ND #' + data.id + ' uspešno izbrisano.');
							this.$refs.spOrdersTable.refresh();
							this.updateSpOrdersTable = false;
							set(spOrdersRefresh, {
								updated_on: new Date().getTime()
							});
						})
						.catch(error => {
							this.isLoadingDeleting = false;
							console.error(error);
							if(error.response && error.response.data && error.response.data.code) {
								this.$refs.toastr.e('Napaka pri brisanju naročila ND! (' + error.response.data.code + ')');
							} else {
								this.$refs.toastr.e('Napaka pri brisanju naročila ND! Prosimo poizkusite kasneje.');
							}
						});
				}
			}).catch(err => {
				console.error(err);
			})
		},
		click_spOrderArchive: function (data) {
			this.$bvModal.msgBoxConfirm('Želite arhivirati naročilo ND #' + data.id + '?', {
				title: 'Potrdite arhiviranje naročila ND',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.isLoadingDeleting = true;
					this.$store.dispatch(SP_ORDER_ARCHIVE, { id: data.id })
						.then((responseData) => {
							this.isLoadingDeleting = false;
							this.$refs.toastr.s('Naročilo ND #' + data.id + ' uspešno arhivirano.');
							this.$refs.spOrdersTable.refresh();
							this.updateSpOrdersTable = false;
							set(spOrdersRefresh, {
								updated_on: new Date().getTime()
							});
						})
						.catch(error => {
							this.isLoadingDeleting = false;
							console.error(error);
							if(error.response && error.response.data && error.response.data.code) {
								this.$refs.toastr.e('Napaka pri arhiviranju naročila ND! (' + error.response.data.code + ')');
							} else {
								this.$refs.toastr.e('Napaka pri arhiviranju naročila ND! Prosimo poizkusite kasneje.');
							}
						});
				}
			}).catch(err => {
				console.error(err);
			})
		},
		click_spOrderUnarchive: function (data) {
			this.$bvModal.msgBoxConfirm('Želite prenesti iz arhiva naročilo ND #' + data.id + '?', {
				title: 'Potrdite prenos iz arhiva naročila ND',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.isLoadingDeleting = true;
					this.$store.dispatch(SP_ORDER_UNARCHIVE, { id: data.id })
						.then((responseData) => {
							this.isLoadingDeleting = false;
							this.$refs.toastr.s('Naročilo ND #' + data.id + ' preneseno iz arhiva.');
							this.$refs.spOrdersTable.refresh();
							this.updateSpOrdersTable = false;
							set(spOrdersRefresh, {
								updated_on: new Date().getTime()
							});
						})
						.catch(error => {
							this.isLoadingDeleting = false;
							console.error(error);
							if(error.response && error.response.data && error.response.data.code) {
								this.$refs.toastr.e('Napaka pri prenosu iz arhiva naročila ND! (' + error.response.data.code + ')');
							} else {
								this.$refs.toastr.e('Napaka pri prenosu iz arhiva naročila ND! Prosimo poizkusite kasneje.');
							}
						});
				}
			}).catch(err => {
				console.error(err);
			})
		},
		getClientLabel: function(client) {
			let label = '';
			if (!client) {
				return label;
			}
			if (client.is_company) {
				label += client.company_title ? client.company_title + ' ' : '';
				label += client.email ? '(' + client.email + ')' : '';
			} else {
				label += client.first_name ? client.first_name + ' ' : '';
				label += client.last_name ? client.last_name + ' ' : '';
				label += client.email ? '(' + client.email + ')' : '';
			}
			if (label.length > 30) {
				return label.substring(0, 27) + '...';
			}
			return label;
		},
		filter_clientsFilterSelect: (option, label, search) => {
			let s = search.toLowerCase();
			if (option.is_company) {
				let text = (option.company_title ? option.company_title.toLowerCase() : '') + ' ' + (option.email ? option.email.toLowerCase() : '');
				let t = (text.indexOf(s) > -1);
				return t;
			} else {
				let text = (option.first_name ? option.first_name.toLowerCase() : '') + ' ' + (option.last_name ? option.last_name.toLowerCase() : '');
				let t = (text.indexOf(s) > -1);
				return t;
			}
		},
		getClientFilterSelectLabel: function(client, slot) {
			let label = '';
			if (!client) {
				return '';
			}
			if (client.is_company) {
				label += client.company_title ? client.company_title + ' ' : '';
				label += client.email ? '(' + client.email + ')' : '';
			} else {
				label += client.first_name ? client.first_name + ' ' : '';
				label += client.last_name ? client.last_name + ' ' : '';
				label += client.email ? '(' + client.email + ')' : '';
			}
			if (slot === 'selected-option' && label.length > 15) {
				return label.substring(0, 12) + '...';
			}
			return label;
		},
		change_clientsFilterSelect: function (client) {
			this.spOrdersTableProps.inputFilter.clientId = null;
			if (client) {
				this.spOrdersTableProps.inputFilter.clientId = client.id;
			} else {
				this.spOrdersTableProps.inputFilter.clientId = null;
			}
			this.click_spOrdersTableRefresh();
		},
		vozilaSelect_load: _.debounce((loading, search, context) => {
			loading(true);
			context.$store.dispatch(VEHICLES_LIST_FILTERED, search )
				.then((responseData) => {
					loading(false);
					context.vehicle_options = responseData.items;
				})
				.catch(error => {
					loading(false);
					if(error.response && error.response.data && error.response.data.code) {
						context.showAlert('Napaka pri pridobivanju vozil! (' + error.response.data.code + ')', context.ALERT_TYPE_ERROR);
					} else {
						context.showAlert('Napaka pri pridobivanju vozil! Prosimo poizkusite kasneje.', context.ALERT_TYPE_ERROR);
					}
				});
		}, 500),
		vehicleSelect_search: function (search, loading) {
			if (search && search !== '') {
				this.vozilaSelect_load(loading, search, this);
			}
		},
		vehicleSelect_change: function (value) {
			if (value) {
				this.spOrdersTableProps.inputFilter.vehicleId = value;
			} else {
				this.spOrdersTableProps.inputFilter.vehicleId = null;
			}
			this.vehicle_options = [];
			this.click_spOrdersTableRefresh();
		},
		filter_brandsSelect: (option, label, search) => {
			let s = search.toLowerCase();
			let text = option.title ? option.title.toLowerCase() : ''
			let t = text.indexOf(s) > -1;
			return t;
		},

		change_usersFilterSelect: function (user) {
			if (user) {
				this.spOrdersTableProps.inputFilter.createdBy = user.id;
			} else {
				this.spOrdersTableProps.inputFilter.createdBy = null;
			}
			this.click_spOrdersTableRefresh();
		},
		getBrandLogoClass: function (logo) {
			return {
				'display': 'inline-block',
				'width': '3rem',
				'height': '2rem',
				'background-repeat': 'no-repeat',
				'background-position': 'center',
				'background-size': 'contain',
				'background-image': 'url( ' + logo + ')'
			}
		},
		getSpOrderItemStatus: function(item) {
			let spOrderItemStatus = this.settings.spOrderItemsStatusOptions.find((status) => status.value === item.lastSpOrderItemStatus);
			if (spOrderItemStatus) {
				return spOrderItemStatus;
			}
			return {
				label: 'Neznan status',
				variant: 'info'
			}
		},
		change_spOrderStatusFilter: function (selectedItems) {
			if (selectedItems && selectedItems.length > 0) {
				// this.spOrdersTableProps.inputFilter.status = selectedItems[0].value;
				this.spOrdersTableProps.inputFilter.status = [];
				for (let item of selectedItems) {
					this.spOrdersTableProps.inputFilter.status.push(item.value);
				}
				this.$store.dispatch(USER_SP_ORDERS_STATUS_FILTER, this.spOrdersTableProps.inputFilter.status)
					.then((responseData) => {
					})
					.catch(error => {
						console.error(error);
				});
			} else {
				this.$store.dispatch(USER_SP_ORDERS_STATUS_FILTER, null)
					.then((responseData) => {
					})
					.catch(error => {
						console.error(error);
				});
				this.spOrdersTableProps.inputFilter.status = null;
			}
			this.click_spOrdersTableRefresh();
		},
		rowClass: function (item) {
			// this should not happen, se we return info class
			if (!item) {
				return 'table-info';
			}
			let rowClass = '';
			if (!item.viewed_record || (item.viewed_record && item.viewed_record.viewed_at === null)) {
				rowClass = 'notViewed ';
			}
			// if there is no sp order items we return danger class (red)
			if (!item.sp_order_items || item.sp_order_items.length < 1) {
				return rowClass + 'table-danger';
			}
			// if any of items have no status we return without class (white)
			if (item.sp_order_items.filter(el => !el.status).length > 0) {
				return rowClass;
			}
			// if last item status is Na zalogi we return success class (green)
			if (item.lastSpOrderItemStatus === this.settings.spOrderItemsStatusOptions.at(-2).value) {
				return rowClass + 'table-success';
			}
			// else we return danger class (red)
			return rowClass + 'table-danger';	
		},
		lastEditedWarning: function (item) {
			if (!item) {
				return '';
			}
			let cutOffDate = new Date();
			cutOffDate.setUTCHours(0,0,0,0);
			cutOffDate.setDate(cutOffDate.getDate() - 4);
			let lastEditDate = new Date(item.updated_at);
			if (cutOffDate < lastEditDate) {
				return '';
			}
			if (item.sp_order_items > 0 && item.sp_order_items[0].updated_at) {
				let lastEditDate = new Date(item.lastUpdatedSpOrderItem);
				if (cutOffDate < lastEditDate) {
					return '';
				}
			}
			return {
				title: 'Zadnje urejanje dne ' + this.$options.filters.viewDate(lastEditDate),
				variant: 'warning'
			}
		},
		change_spOrderBrandFilter: function (selectedItems) {
			if (selectedItems && selectedItems.length > 0) {
				this.spOrdersTableProps.inputFilter.brands = [];
				for (let item of selectedItems) {
					this.spOrdersTableProps.inputFilter.brands.push(item.id);
				}
				this.$store.dispatch(USER_SP_ORDERS_BRANDS_FILTER, this.spOrdersTableProps.inputFilter.brands)
					.then((responseData) => {
					})
					.catch(error => {
						console.error(error);
				});
			} else {
				this.$store.dispatch(USER_SP_ORDERS_BRANDS_FILTER, null)
					.then((responseData) => {
					})
					.catch(error => {
						console.error(error);
				});
				this.spOrdersTableProps.inputFilter.brands = null;
			}
			this.click_spOrdersTableRefresh();
		},
		change_spOrderLocationFilter: function (selectedItems) {
			if (selectedItems && selectedItems.length > 0) {
				this.spOrdersTableProps.inputFilter.location = selectedItems[0].label;
				this.$store.dispatch(USER_SP_ORDERS_LOCATION_FILTER, this.spOrdersTableProps.inputFilter.location)
					.then((responseData) => {
					})
					.catch(error => {
						console.error(error);
				});
			} else {
				this.$store.dispatch(USER_SP_ORDERS_LOCATION_FILTER, null)
					.then((responseData) => {
					})
					.catch(error => {
						console.error(error);
				});
				this.spOrdersTableProps.inputFilter.location = null;
			}
			this.click_spOrdersTableRefresh();
		},
		isCurrentlyEdited: function (item) {
			if (!item.latest_editing_record) return false;
			if (item.latest_editing_record.ended_at != null) return false;
			let now = new Date();
			let limit = new Date(item.latest_editing_record.started_at);
			let limitTime = addSeconds(limit, this.editingRecordType.show_lock_length);
			if (now > limitTime) return false;
			return true;
		},
		isEditingDisabled: function (item) {
			if (!item.latest_editing_record) return false;
			if (item.latest_editing_record.ended_at != null) return false;
			if (item.latest_editing_record.started_by_user.id === this.profile.id) return false;
			let now = new Date();
			let limit = new Date(item.latest_editing_record.started_at);
			let limitTime = addSeconds(limit, this.editingRecordType.lock_length);
			if (now > limitTime) return false;
			return true;
		},
		getEditingLockTitle: function (item) {
			return 'Naročilo je začel urejati ' + item.latest_editing_record.started_by_user.name + ' dne ' + this.$options.filters.viewDate(item.latest_editing_record.started_at) + ' ob ' + this.$options.filters.viewTime(item.latest_editing_record.started_at);
		},
		emit_spOrderEditingRecordStartError: function () {
			this.$refs.toastr.e('Napaka pri začetku urejanja naročila!');
		},
		emit_spOrderSetView: function (userViewedSpOrder) {
			this.currenlyEditSpOrder.viewed_record = userViewedSpOrder;
		},
		click_spOrderItemsTableRefresh: function () {
			this.$refs.spOrderItemsTable.refresh();
		},
		spOrderItemsTable_items: function (ctx) {
			this.spOrderItemsTableProps.isLoading = true;
			return this.$store.dispatch(SP_ORDER_ITEMS_LIST_FILTERED, { ctx: ctx, tableProps: this.spOrderItemsTableProps })
				.then((responseData) => {
					this.spOrderItemsTableProps.isLoading = false;
					this.spOrderItemsTableProps.items = responseData.spOrderItemsPagination.data;
					this.spOrderItemsTableProps.totalItems = responseData.spOrderItemsPagination.total;
					this.spOrderItemsTableProps.empty_text = 'Ni vnosov';
					return this.spOrderItemsTableProps.items;
				})
				.catch(error => {
					this.spOrderItemsTableProps.isLoading = false;
					this.spOrderItemsTableProps.items = [];
					this.spOrderItemsTableProps.totalItems = 0;
					this.spOrderItemsTableProps.empty_text = 'Napaka pri pridobivanju postavk naročila';
					console.log(error);
					return [];
				});
		},
		spOrderItemRowClicked (row) {
			this.currenlyEditSpOrder = row.sp_order;
			let modalInput = {
				mode: 3, // details mode
				spOrder: row.sp_order,
				editingRecordType: this.editingRecordType
			}
			this.$refs.spOrderCreateEditModal.showModal(modalInput);
		},
		downloadSpOrderItemsDebt: function (type) {
			this.spOrderItemsTableProps.isLoading = true;
			return this.$store.dispatch(SP_ORDER_ITEMS_FILE_DOWNLOAD_DEBT, { type: type })
				.then((response) => {
					this.spOrderItemsTableProps.isLoading = false;
					var newBlob = new Blob([response.data], {type: response.headers['content-type']});
					const data = window.URL.createObjectURL(newBlob);
					var link = document.createElement('a');
					link.href = data;
					link.download = 'dolgovanje_'+this.$options.filters.viewDate(new Date())+'.'+type;
					link.click();
					setTimeout(function () {
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(data)
					}, 100);
				})
				.catch(error => {
					this.spOrderItemsTableProps.isLoading = false;
					console.log(error);
					this.$refs.toastr.e('Napaka! Prosimo poizkusite kasneje.');
				});
		},
		change_spOrderDepartmentFilter: function (selectedItems) {
			if (selectedItems && selectedItems.length > 0) {
				this.spOrdersTableProps.inputFilter.department = selectedItems[0].label;
				this.$store.dispatch(USER_SP_ORDERS_DEPARTMENT_FILTER, this.spOrdersTableProps.inputFilter.department)
					.then((responseData) => {
					})
					.catch(error => {
						console.error(error);
				});
			} else {
				this.$store.dispatch(USER_SP_ORDERS_DEPARTMENT_FILTER, null)
					.then((responseData) => {
					})
					.catch(error => {
						console.error(error);
				});
				this.spOrdersTableProps.inputFilter.department = null;
			}
			this.click_spOrdersTableRefresh();
		},
		handleScroll: function () {
			let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
			if (bottomOfWindow && this.spOrdersTableProps.totalItems > this.spOrdersTableProps.items.length) {
				this.spOrdersTableProps.itemsPerPage += this.spOrdersTableProps.itemsPerPage;
				this.$refs.spOrdersTable.refresh();
			}
		},
		spOrdersSortChanged: function (sort) {
			this.$store.dispatch(USER_SP_ORDERS_SORTING, [sort.sortBy, sort.sortDesc])
				.then((responseData) => {
				})
				.catch(error => {
					console.error(error);
			});
		}
	},
	created: function () {
		let $main = this;
		onValue(spOrdersRefresh, (snapshot) => {
			if ($main.updateSpOrdersTable) {
				$main.$refs.spOrdersTable.refresh();
				if ($main.selectedTabIndex === 1 && $main.updateSpOrderItemsTable) {
					$main.$refs.spOrderItemsTable.refresh();
				}
				$main.$refs.toastr.s('Podatki posodobljeni');
			}
			$main.updateSpOrdersTable = true;
			$main.updateSpOrderItemsTable= true;
		});
		if (!this.permissionCheck.is('klepar')) {
			this.$store.dispatch(USERS_LIST_ALL_WITH_SP_ORDERS_WRITE_PERMISSION)
				.then((responseData) => {
					console.log(responseData);
					this.allUsersWithSpOrdersWritePermission = responseData.usersWithSpOrdersWritePermission;
				})
				.catch(error => {
					console.error(error);
			});
		}
		this.spOrderItemsStatusOptions = structuredClone(this.settings.spOrderItemsStatusOptions);
		this.spOrderItemsStatusOptions.push({
			label: 'V pripravi',
			value: '#',
			variant: 'light',
			active: false
		});
		window.addEventListener('scroll', this.handleScroll);
	},
	mounted() {
		if (this.$store.getters.getUserSpOrdersBrandsFilter) {
			this.spOrdersTableProps.inputFilter.brands = this.$store.getters.getUserSpOrdersBrandsFilter;
		}
		if (this.$store.getters.getUserSpOrdersDepartmentFilter) {
			this.spOrdersTableProps.inputFilter.department = this.$store.getters.getUserSpOrdersDepartmentFilter;
		}
		if (this.$store.getters.getUserSpOrdersLocationFilter) {
			this.spOrdersTableProps.inputFilter.location = this.$store.getters.getUserSpOrdersLocationFilter;
		}
		if (this.$store.getters.getUserSpOrdersStatusFilter) {
			this.spOrdersTableProps.inputFilter.status = this.$store.getters.getUserSpOrdersStatusFilter;
		}
		if (this.$store.getters.getUserSpOrdersSorting) {
			let sorting = this.$store.getters.getUserSpOrdersSorting;
			this.spOrdersTableProps.sortBy = sorting[0];
			this.spOrdersTableProps.sortDesc = sorting[1];
		}
	}
}
</script>
<style scoped>
.table-responsive-sm {
	margin-bottom: 0;
}
.pagination {
	margin-left: 1rem;
	margin-right: 1rem;
}
.page-link.rows_total {
	text-align: center;
	border-radius: 5px;
	width: 120px;
}
</style>
<style>
.tdSpOrdersId {
	width: 1rem;
}
.tdSpOrderDate {
	width: 9rem;
	min-width: 9rem;
}
.tdSpOrderCreatedByUserName {
	width: 5rem;
}
.tdSpOrderDepartment {
	width: 5rem;
}
.tdSpOrderNumber {
	width: 7rem;
	min-width: 7rem;
}
.tdSpOrderClientName {
	width: 7.5rem;
}
.tdSpOrderRegistrationNumber {
	width: 8rem;
	min-width: 8rem;
}
.tdSpOrderVin {
	width: 15rem;
	min-width: 15rem;
}
.tdSpOrderBrand {
	width: 6rem;
	min-width: 6rem;
}
.tdSpOrderModel {
	width: 10rem;
	min-width: 10rem;
}
.tdSpOrderLocation {
	width: 7rem;
}
.tdSpOrderStatus {
	width: 9rem;
	min-width: 9rem;
	max-width: 9rem;
}
.tdSpOrdersActions {
	width: 9rem;
	min-width: 9rem;
}
.spOrdersTable {
	min-height: 500px;
}
.notViewed {
	font-weight: bold;
}
.table thead th[aria-sort="descending"] {
    cursor: pointer;
}
.table thead th[aria-sort="descending"] div:after {
	content:'↓';
	margin-left: 0.5em;
}
.table thead th[aria-sort="ascending"] {
    cursor: pointer;
}
.table thead th[aria-sort="ascending"] div:after {
	content:'↑';
	margin-left: 0.5em;
}
.table thead th[aria-sort="none"] {
    cursor: pointer;
}
</style>