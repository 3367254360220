<script>
import settings from '@/settings.js';

export default {
	name: 'SpOrderDepartmentFilterDropdown',
	methods: {
		click_item (item) {
			this.allSelected = false;
			this.selectItem(item.label);
			this.$emit('change', this.selectedItems);
		},
		click_all (evt) {
			this.selectItem();
			this.allSelected = true;
			this.$emit('change', []);
		},
		selectItem (value) {
			this.selectedItems = [];
			for (let item of this.items) {
				if (this.multiselect) {
					if (value) {
						if (value === item.value) {
							item.active = !item.active;
						}
					} else {
						item.active = false;
					}
				} else {
					if (value) {
						if (value === item.label) {
							item.active = true;
						} else {
							item.active = false;
						}
					} else {
						item.active = false;
					}
				}
				if (item.active) {
					this.selectedItems.push(item);
				}
			}
			if (this.selectedItems.length === 0) {
				this.allSelected = true;
			}
		},
		clear () {
			this.selectItem();
		}
	},
	computed: {
		selectedTitle () {
			return this.defaultItemLabel;
		},
		items () {
			if (this.$store.getters.getUserSpOrdersDepartmentFilter && this.$store.getters.getUserSpOrdersDepartmentFilter.length > 0) {
				this.allSelected = false;
				let selectedDepartment = this.settings.spOrderDepartmentOptions.find((item) => item.label === this.$store.getters.getUserSpOrdersDepartmentFilter);
				if (selectedDepartment) selectedDepartment.active = true;
			}
			return this.settings.spOrderDepartmentOptions;
		}
	},
	data: () => {
		return {
			defaultItemLabel: 'Vse',
			selectedItems: [],
			multiselect: false,
			allSelected: true,
			settings
		}
	}
}
</script>
<template>
<b-dropdown variant="primary" :text="selectedTitle" ref="dropdown">
	 <template #button-content>
		<span v-if="allSelected">Vse</span>
		<span v-else v-for="item in items" :key="item.value" >
			<span v-if="item.active">
				{{ item.label }}
			</span>
		</span>
	 </template>
	<b-dropdown-header>Lokacija</b-dropdown-header>
	<b-dropdown-item @click="click_item(item)" v-for="item in items" :key="item.value" :active="item.active">
		{{ item.label }}
	</b-dropdown-item>
	<b-dropdown-divider></b-dropdown-divider>
	<b-dropdown-item @click="click_all" :active="allSelected">{{ defaultItemLabel }}</b-dropdown-item>
</b-dropdown>
</template>
<style>
</style>