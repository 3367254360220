const getVehicleBrandImage = function(logo, size=3) {
	return {
		'display': 'inline-block',
		'width': size + 'rem',
		'height': size + 'rem',
		'background-repeat': 'no-repeat',
		'background-position': 'center',
		'background-size': 'contain',
		'background-image': 'url( ' + getPublicImageUrl(logo) + ')'
	}
};
const getVehicleBrandIcon = function(size=3) {
	return {
		'width': size + 'rem',
		'height': size + 'rem'
	}
};
const getPublicImageUrl = function(path) {
	if (path.startsWith('http')) {
		return path;
	}
	return process.env.VUE_APP_API.replace('api/', path);
};
export default {
	vehicleBrandImage: getVehicleBrandImage,
	vehicleBrandIcon: getVehicleBrandIcon,
};